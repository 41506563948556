import React from "react";

import SelectKeyNumber from '../framework/selectkeynumber';

function DataSourceSelect(props) {

    var dataSourcesUi = props.dataSources.map(dataSource => (
        { value: dataSource.Id, label : dataSource.Name }
    ));

    dataSourcesUi.unshift({ value: -1, label: "Choose a dataSource" });

    const changeCurrentDataSource = (id) => {
        props.setCurrentDataSource(props.dataSources.find((dataSource) => dataSource.Id === id) ?? null);
    }

    if (props.dataSources.length === 0)
    {
        return (<div>NO DATA</div>);
    }

    var currentDataSourceId = props.currentDataSource != null ? props.currentDataSource.Id : -1;

    return (
        <SelectKeyNumber
            options={dataSourcesUi}
            value={currentDataSourceId}
            onChange={newValue => changeCurrentDataSource(newValue)}
        />);
}

export default DataSourceSelect;