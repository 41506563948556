import 'bootstrap/dist/css/bootstrap.css';
import React from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import App from './App';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import reportWebVitals from './reportWebVitals';

import { PublicClientApplication, EventType } from "@azure/msal-browser";
import { MsalProvider } from "@azure/msal-react";

import { msalConfig } from './AuthConfig';

import config from 'devextreme/core/config';
import { licenseKey } from './devextreme-license';

config({ licenseKey });

const baseUrl = document.getElementsByTagName('base')[0].getAttribute('href');
const rootElement = document.getElementById('root');
const root = createRoot(rootElement);

export const publicClientApplication = new PublicClientApplication(msalConfig);

publicClientApplication.initialize().then(() => {
    // Default to using the first account if no account is active on page load
    if (!publicClientApplication.getActiveAccount() && publicClientApplication.getAllAccounts().length > 0) {
        // Account selection logic is app dependent. Adjust as needed for different use cases.
        publicClientApplication.setActiveAccount(publicClientApplication.getAllAccounts()[0]);
    }

    // Optional - This will update account state if a user signs in from another tab or window
    publicClientApplication.enableAccountStorageEvents();

    publicClientApplication.addEventCallback((event) => {
        if (event.eventType === EventType.LOGIN_SUCCESS && event.payload.account) {
            const account = event.payload.account;
            publicClientApplication.setActiveAccount(account);
        }
    });

    root.render(
        <BrowserRouter basename={baseUrl}>
            <MsalProvider instance={publicClientApplication}>
                <App />
            </MsalProvider>
        </BrowserRouter>);

    // If you want your app to work offline and load faster, you can change
    // unregister() to register() below. Note this comes with some pitfalls.
    // Learn more about service workers: https://cra.link/PWA
    serviceWorkerRegistration.unregister();

    // If you want to start measuring performance in your app, pass a function
    // to log results (for example: reportWebVitals(console.log))
    // or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
    reportWebVitals();

});