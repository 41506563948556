import React from 'react';
import { useMsal } from '@azure/msal-react';
import { NavLink } from 'reactstrap';

export const NavLogout = () => {
    const { instance } = useMsal();

    const initializeSignOut = () => {
        instance.logoutRedirect();
    };

    return (
        <div className="dx-widget sign-out">
            <div className="dx-tab-text">
                <span className="sign-out-text" onClick={initializeSignOut}>
                    Sign out
                </span>
            </div>
        </div>

    );
};
