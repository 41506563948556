class GetTestDataSourceCredentialResult {
    Ok: boolean;
    Error: string;
    
    constructor(ok: boolean, error: string) {
        this.Ok = ok;
        this.Error = error;
    }
}

export default GetTestDataSourceCredentialResult;
