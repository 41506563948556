import React from 'react';

import { FormGroup, Label } from 'reactstrap';

function FormField(props) {

    return (
        <FormGroup>
            <Label>{props.label}</Label>
            {props.children}
        </FormGroup>
    );
}

export default FormField