﻿class DataSourceAuthenticationDetailDto {

    Label: string;
    Name: string;

    constructor(label: string, name: string) {
        this.Label = label;
        this.Name = name;
    }
}

export default DataSourceAuthenticationDetailDto;