import React from 'react';

import { FormGroup, Label, Input } from 'reactstrap';

function Switch(props) {

    const checkChange = (key) => {
        if (props.onChange != null) {
            props.onChange(key.target.checked);
        }
    };

    var style = {};

    if (props.width != null) {
        style.width = props.width;
    }

    if (props.marginLeft != null) {
        style.marginLeft = props.marginLeft;
    }

    if (props.marginRight != null) {
        style.marginRight = props.marginRight;
    }

    return (
        <FormGroup style={style} switch>
            <Input type="switch" role="switch" checked={props.value} onChange={checkChange} />
            <Label check>{props.label}</Label>
        </FormGroup>
    );
}

export default Switch