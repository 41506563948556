import DataSourceAuthenticationDto from './DataSourceAuthenticationDto';
import DataSourceConnectorTypeListDto from './DataSourceConnectorTypeListDto';

class DataSourceTypeListDto {

    Id: number;
    Name: string;
    SourceType: number;
    ConnectorTypes: DataSourceConnectorTypeListDto[];
    Authentications: DataSourceAuthenticationDto[];

    constructor(id: number, name: string, sourceType: number, connectorTypes: DataSourceConnectorTypeListDto[], authentications: DataSourceAuthenticationDto[]) {
        this.Id = id;
        this.Name = name;
        this.SourceType = sourceType;
        this.ConnectorTypes = connectorTypes;
        this.Authentications = authentications;
    }
}

export default DataSourceTypeListDto;