import React from 'react';

function Label(props) {

    var style = {};

    if (props.color != null) {
        style.color = props.color;
    }

    if (props.width != null) {
        style.width = props.width;
    }

    if (props.marginLeft != null) {
        style.marginLeft = props.marginLeft;
    }

    if (props.marginRight != null) {
        style.marginRight = props.marginRight;
    }

    return (
        <label style={style} className={props.className}>
            {props.children}
        </label>
    );
}

export default Label