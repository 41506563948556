import React from 'react';

import DataSource from '../../frontend/components/datasourcemodule/datasource';
import FieldsList from '../../frontend/components/datasourcemodule/fields-list';
import { DataViewer } from '../dataviewer/DataViewer'

import '../../common.css';
import './pathsynchro.css';

export function PathSynchroSource(props) {

    return (
        <div className='nowcode-columns path-height'>
            <div className='path-synchro-40p path-synchro-mr nowcode-internal-block'>
                <DataSource
                    direction="Source"
                    currentDataSource={props.currentDataSource}
                    setCurrentDataSource={props.setCurrentDataSource}

                    currentDatabaseReference={props.currentDatabaseReference}
                    setCurrentDatabaseReference={props.setCurrentDatabaseReference}

                    currentTableReference={props.currentTableReference}
                    setCurrentTableReference={props.setCurrentTableReference}
                    fields={props.fields}
                    setFields={props.setFields}
                    tableReferenceLoaded={props.mapping?.Source}
                    fullLoadingInProgress={props.fullLoadingInProgress}
                    >
                </DataSource>
                <FieldsList
                    dataSource={props.currentDataSource}
                    databaseReference={props.currentDatabaseReference}
                    tableReference={props.currentTableReference}
                    fields={props.fields}
                    setFields={props.setFields}
                    fullLoadingInProgress={props.fullLoadingInProgress}
                    >
                </FieldsList>
            </div>
            <div className='path-synchro-60p nowcode-internal-block'>
                {props.fields && <DataViewer
                    currentDataSource={props.currentDataSource}
                    currentTableReference={props.currentTableReference}
                    fields={props.fields}>
                </DataViewer>}
            </div>
        </div>
    );
}
