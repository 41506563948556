import React, { useEffect, useState, useCallback } from "react";

//import { Button, FormField, Input } from "@airtable/blocks/ui";

import FormField from './../../../components/wrapper/formfield';
import Button from './../../../components/wrapper/button';

import Dialog from '../framework/dialog.js';
import DialogBody from "../framework/dialog-body.js";
import DialogFooter from "../framework/dialog-footer.js";

import FieldReferenceLogic from "../../businesslayers/datastructuremodule/FieldReferenceLogic";

import SelectKeyNumber from './../framework/selectkeynumber';

import '../../styles/nowcode-toolboxline.css';

const TransformationDialog = (props) => {

    const [fields, setFields] = useState(null);

    const [current, setCurrent] = useState(props.currentLinkedField);

    const isValid = function() {
        return true;
    }
    
    const createOrUpdateDataSource = function () {

        props.setCurrentLinkedField(current);
        props.setDialogIsOpen(false);

    }

    const cancel = function() {
        props.setDialogIsOpen(false);
    }

    const sourceFieldsLoad = useCallback(async () => {
        if (props.tableReference != null) {
            if (!fields) {

                var getFieldsReferencesResult = await FieldReferenceLogic.GetFieldReferencesAsync(props.dataSource, props.tableReference, true);

                if (getFieldsReferencesResult.IsFailed()) {
                    // ERROR
                }

                var allFields = getFieldsReferencesResult.Result.Fields;

                var fieldsUi = allFields
                        .map(field => ({ value: field.Id, label: field.Label }));

                fieldsUi.unshift({ value: null, label: "Choose a field" });

                setFields(fieldsUi);
            }
        }
    }, [fields]);


    useEffect(() => {
        sourceFieldsLoad()
    }, [sourceFieldsLoad]);

    return (
        <React.Fragment>
            {(
                <Dialog onClose={() => cancel()} title={"Linked field"} width="320px">
                    <DialogBody>

                        <FormField label="field">
                            <SelectKeyNumber
                                options={fields}
                                value={current}
                                onChange={newValue => setCurrent(newValue)} />
                        </FormField>
                    </DialogBody>
                    <DialogFooter>
                        <div className="nowcode-dialog-footer">
                            <Button marginRight={1} onClick={createOrUpdateDataSource} disabled={!isValid()}>Update</Button>
                            <Button onClick={cancel}>Cancel</Button>
                        </div>
                    </DialogFooter>
                </Dialog>
            )}
        </React.Fragment>
    );
};

export default TransformationDialog;