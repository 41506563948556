﻿import { Configuration } from '@azure/msal-browser';

export const msalConfig: Configuration = {
    auth: {
        clientId: process.env.REACT_APP_B2C_CLIENTID,
        authority: process.env.REACT_APP_B2C_AUTHORITY,
        knownAuthorities: [process.env.REACT_APP_B2C_KNOWAUTHORITIES],
        redirectUri: "/signin-oidc"
    }
}

export const apiScopes = [process.env.REACT_APP_B2C_APISCOPES];