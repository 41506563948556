import FieldReferenceIdAndKeyDto from "../dtos/FieldReferenceIdAndKeyDto";

class GetFieldReferencesResult {
    Status: string;
    Fields: FieldReferenceIdAndKeyDto[];

    constructor(status: string, fields: FieldReferenceIdAndKeyDto[]) {
        this.Status = status;
        this.Fields = fields;
    }
}

export default GetFieldReferencesResult;


