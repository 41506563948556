import React from "react";

import SourceType from "./../../const/datasourcemodule/SourceType";

//import { FormField, Input } from "@airtable/blocks/ui";
import FormField from './../../../components/wrapper/formfield';
import Input from './../../../components/wrapper/input';

import DataSourceWithCustomerAgent from "./datasource-dialog-with-customeragent";
import DataSourceCredentialSelect from "./datasourcecredential-select";

function DataSourceDialogBody(props) {

    if (props.dataSourceType == null || props.dataSourceConnectorType == null) {
        return ("");      
    }

    if ((props.dataSourceType.SourceType === SourceType.WithIncludedCredentials) && props.dataSourceConnectorType.Id === 3) {
        // SqlServer / CustomerAgent
        return (
            <div>
                <DataSourceWithCustomerAgent
                    action={props.action}
                    current={props.current}
                    customerAgent={props.customerAgent}
                    setCustomerAgent={props.setCustomerAgent} />
                <FormField label="Connection string">
                    <Input value={props.connectionString} onChange={e => props.setConnectionString(e.target.value)} />
                </FormField>
            </div>);
    }

    if (props.dataSourceType.SourceType === SourceType.WithSeparatedCredentials) {
        // Airtable et Timetonic
        return (
            <div>
                {(props.dataSourceConnectorType.Id === 3) &&
                    <DataSourceWithCustomerAgent
                        action={props.action}
                        current={props.current}
                        customerAgent={props.customerAgent}
                        setCustomerAgent={props.setCustomerAgent} />}
                <FormField label="Credential">
                    <DataSourceCredentialSelect
                        defaultId={props.current?.DataSourceCredentialId ?? null}
                        dataSourceCredential={props.dataSourceCredential}
                        setDataSourceCredential={props.setDataSourceCredential}
                        dataSourceType={props.dataSourceType} />
                </FormField>
            </div>);
    }


    return ("");      
}

export default DataSourceDialogBody;