import React, { useCallback } from 'react';

import { DataGrid, Selection, Column } from 'devextreme-react/data-grid';
import CustomStore from 'devextreme/data/custom_store'

import { Spinner } from 'reactstrap';

import '../../../common.css';

function PathGrid(props) {

    const onSelectionChanged = useCallback(({ selectedRowsData }: DataGridTypes.SelectionChangedEvent) => {
        props.openPath(selectedRowsData[0].Id, selectedRowsData[0].TypeId)
    }, [props]);

    const customDataSource = new CustomStore({
        key: 'Id',
        load: () => {
            return props.paths;
        }
    });

    if (!props.paths) return (<Spinner color="primary" className="spinner">Chargement en cours</Spinner>);

    return (
        <div>
            <DataGrid id="dataGrid"
                dataSource={customDataSource}
                allowColumnReordering={true}
                allowColumnResizing={true}
                columnAutoWidth={true}
                hoverStateEnabled={true}
                onSelectionChanged={onSelectionChanged}            >
                <Selection mode="single" />
                <Column caption="Name" dataField="Name" dataType="string" />
                <Column caption="Type" dataField="TypeLabel" dataType="string" />
                <Column caption="Created by" dataField="CreatedBy" dataType="string" />
                <Column caption="Creation date" dataField="CreationDate" dataType="datetime" />
                <Column caption="Update date" dataField="UpdateDate" dataType="datetime" />
                <Column caption="Current runs" dataField="CurrentPeriodRuns" dataType="integer" />
                <Column caption="All runs" dataField="AllPeriodsRuns" dataType="integer" />
            </DataGrid>
        </div>);
}

export default PathGrid;
