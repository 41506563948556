import React from "react";

import Box, { Item } from 'devextreme-react/box';

import '../../styles/nowcode-toolboxline.css';

function InfoBlock(props) {

    return (
        <Box direction="col" width="100%" height={80}>
            <Item ratio={1} >
                <Box direction="row" width="100%" height={80}>
                    <Item ratio={1} >
                        <div className="dx-theme-background-color nowcode-alert-container">{props.message}</div>
                    </Item>
                </Box>
            </Item>
        </Box>
    );
}

export default InfoBlock;
