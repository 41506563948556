class GetRequestRowsResult {
    Status: number;
    Rows: object[];

    constructor(status: number, rows: object[]) {
        this.Status = status;
        this.Rows = rows;
    }
}

export default GetRequestRowsResult;
