﻿import ProcessingResult from "../../framework/ProcessingResult";

import Delay from "../../tools/Delay";

import DataSourceApi from "../../apis/DataSourceApi";

import GetTestDataSourceResult from "../../classes/logic/GetTestDataSourceResult";

export default class DataSourceCrudLogic {

    public CurrentDataSourceId: number;

    constructor() {
        this.CurrentDataSourceId = null;
    }

    CreateOrUpdateDataSourceStandardDatabaseAsync = async (customerAgentId: number, dataSourceTypeId: number, name: string, connectionString: string) => {

        if (this.CurrentDataSourceId === null) {
            var createDataSourceStandardDatabaseResult = await DataSourceApi.CreateDataSourceStandardDatabaseAsync(customerAgentId, dataSourceTypeId, name, connectionString);
            if (createDataSourceStandardDatabaseResult.IsFailed()) {
                return ProcessingResult.Error("Error in CreateDataSourceStandardDatabaseAsync " + createDataSourceStandardDatabaseResult.ErrorMessage);
            }
            this.CurrentDataSourceId = createDataSourceStandardDatabaseResult.Result.Id;
        } else {
            var updateDataSourceStandardDatabaseResult = await DataSourceApi.UpdateDataSourceStandardDatabaseAsync(this.CurrentDataSourceId, customerAgentId, dataSourceTypeId, name, connectionString);
            if (updateDataSourceStandardDatabaseResult.IsFailed()) {
                return ProcessingResult.Error("Error in UpdateDataSourceStandardDatabaseAsync " + updateDataSourceStandardDatabaseResult.ErrorMessage);
            }
        }

        var createRequestResult = await DataSourceApi.CreateRequestTestDataSourceAsync(this.CurrentDataSourceId);

        if (createRequestResult.IsFailed()) {
            return ProcessingResult.Error("Error in CreateRequestTestDataSourceAsync " + createRequestResult.ErrorMessage);
        }

        if (createRequestResult.Result.Accepted) {
            var taskId = createRequestResult.Result.Token;
            for (let i = 0; i < 20; i++) {

                var getRequestTestDataSourceResult = await DataSourceApi.GetRequestTestDataSourceAsync(taskId)

                if (getRequestTestDataSourceResult.Result.Status === 4) {
                    return ProcessingResult.Ok(new GetTestDataSourceResult(getRequestTestDataSourceResult.Result.Ok, getRequestTestDataSourceResult.Result.Error));
                }

                await Delay.Sleep(500)
            }

            return ProcessingResult.Error("Request timeout !");
        }
        else {
            return ProcessingResult.Error("Request not accepted !");
        }
    }

    CreateOrUpdateDataSourceWithCredentialAsync = async (dataSourceTypeId: number, dataSourceConnectorTypeId: number, name: string, customerAgentId: number, dataSourceCredentialId: number)  => {

        if (this.CurrentDataSourceId === null) {
            var createDataSourceWithCredentialResult = await DataSourceApi.CreateDataSourceWithCredentialAsync(dataSourceTypeId, dataSourceConnectorTypeId, name, customerAgentId, dataSourceCredentialId);
            if (createDataSourceWithCredentialResult.IsFailed()) {
                return ProcessingResult.Error("Error in CreateDataSourceWithCredentialAsync " + createDataSourceWithCredentialResult.ErrorMessage);
            }
            this.CurrentDataSourceId = createDataSourceWithCredentialResult.Result.Id;
        } else {
            var updateDataSourceWithCredentialResult = await DataSourceApi.UpdateDataSourceWithCredentialAsync(this.CurrentDataSourceId, dataSourceTypeId, dataSourceConnectorTypeId, name, customerAgentId, dataSourceCredentialId);
            if (updateDataSourceWithCredentialResult.IsFailed()) {
                return ProcessingResult.Error("Error in UpdateDataSourceWithCredentialAsync " + updateDataSourceWithCredentialResult.ErrorMessage);
            }
        }

        var createRequestResult = await DataSourceApi.CreateRequestTestDataSourceAsync(this.CurrentDataSourceId);

        if (createRequestResult.IsFailed()) {
            return ProcessingResult.Error("Error in CreateRequestTestDataSourceAsync " + createRequestResult.ErrorMessage);
        }

        if (createRequestResult.Result.Accepted) {
            var taskId = createRequestResult.Result.Token;
            for (let i = 0; i < 20; i++) {

                var getRequestTestDataSourceResult = await DataSourceApi.GetRequestTestDataSourceAsync(taskId)

                if (getRequestTestDataSourceResult.Result.Status === 4) {
                    return ProcessingResult.Ok(new GetTestDataSourceResult(getRequestTestDataSourceResult.Result.Ok, getRequestTestDataSourceResult.Result.Error));
                }

                await Delay.Sleep(500)
            }

            return ProcessingResult.Error("Request timeout !");
        }
        else {
            return ProcessingResult.Error("Request not accepted !");
        }
    }
}