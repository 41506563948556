import Settings from "./Settings";
import { AuthToken } from "./AuthToken";

import ProcessingResult from "../framework/ProcessingResult";

import UserSession from "./UserSession";

import GetCustomerAgentsResult from "../classes/api/result/customeragentmodule/GetCustomerAgentsResult";
import CustomerAgentListDto from "../classes/dtos/customeragentmodule/CustomerAgentListDto";

const CustomerAgentApi = {

    GetCustomerAgentsAsync: async function (): Promise<ProcessingResult<GetCustomerAgentsResult>> {
        let authSession = await AuthToken.get();

        let customerId = await UserSession.EnsureAsync(authSession.Token);

        if (customerId === -1) {
            return ProcessingResult.Error("Unhautorized");
        }

        const response = await fetch(Settings.api_url + "/CustomerAgent/GetCustomerAgents?customerId=" + customerId.toString(), {
            method: "Get",
            mode: "cors",
            cache: "no-cache",
            credentials: 'include',
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + authSession.Token
            }
        });

        if (response.status === 200) {
            var data = await response.json();
            return ProcessingResult.Ok(new GetCustomerAgentsResult(data.customerAgents.map((e) => new CustomerAgentListDto(e.id, e.name, e.token, e.type))));
        }

        return ProcessingResult.Error("Error = " + response.status.toString());
    }
};

export default CustomerAgentApi;