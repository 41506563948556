class DataSourceListDto {
    Id: number;
    Name: string;
    Type: string;
    ConnectorType: string;

    constructor(id: number, name: string, type: string, connectorType: string) {
        this.Id = id;
        this.Name = name;
        this.Type = type;
        this.ConnectorType = connectorType;
    }
}

export default DataSourceListDto;
