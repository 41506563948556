class GetRowsResult {
    Status: string;
    Rows: object[];

    constructor(status: string, rows:object[]) {
        this.Status = status;
        this.Rows = rows;
    }
}

export default GetRowsResult;
