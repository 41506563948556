import DataSourceListDto from "../../../dtos/datasourcemodule/DataSourceListDto";

class EnsureAirtableDataSourceResult {
    DataSource: DataSourceListDto;

    constructor(dataSource: DataSourceListDto) {
        this.DataSource = dataSource;
    }
}

export default EnsureAirtableDataSourceResult;
