class DatabaseReferenceIdAndKeyDto {
    Id: number;
    Key: string;
    Name: string;

    constructor(id: number, key: string, name: string) {
        this.Id = id;
        this.Key = key;
        this.Name = name;
    }
}

export default DatabaseReferenceIdAndKeyDto;
