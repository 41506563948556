import TableReferenceIdAndKeyDto from "../../../dtos/TableReferenceIdAndKeyDto";

class GetRequestTablesResult {
    Status: number;
    Tables: TableReferenceIdAndKeyDto[];

    constructor(status: number, tables: TableReferenceIdAndKeyDto[]) {
        this.Status = status;
        this.Tables = tables;
    }
}

export default GetRequestTablesResult;
