import React from "react";

import SelectKeyNumber from '../framework/selectkeynumber';

function TableSelect(props) {

    var tablesUi = props.tables.map(table => (
        { value: table.Id, label : table.getLabel() }
    ));

    tablesUi.unshift({ value: -1, label : "Choose a table" });

    const changeCurrentTable = (id) => {
        props.setCurrentTableReference(props.tables.find((table) => table.Id === id) ?? null);
    }

    if (props.tables.length === 0)
    {
        return (<div>NO DATA</div>);
    }

    var currentTableId = props.currentTableReference != null ? props.currentTableReference.Id : -1;

    return (
        <SelectKeyNumber
            options={tablesUi}
            value={currentTableId}
            onChange={newValue => changeCurrentTable(newValue)}
            disabled={props.tableIsOpen}
        />);
}

export default TableSelect;