import DatabaseReferenceIdAndKeyDto from "../../../dtos/DatabaseReferenceIdAndKeyDto";

class GetRequestDatabasesResult {
    Status: number;
    Databases: DatabaseReferenceIdAndKeyDto[];

    constructor(status: number, databases: DatabaseReferenceIdAndKeyDto[]) {
        this.Status = status;
        this.Databases = databases;
    }
}

export default GetRequestDatabasesResult;
