import React, { Component } from 'react';
import { AuthenticatedTemplate, UnauthenticatedTemplate } from '@azure/msal-react';
import Pathta from "./asset/Pathta.webp";
import './Home.css';
export class Home extends Component {
  static displayName = Home.name;

  render() {
    return (
      <div>
        <AuthenticatedTemplate>
        </AuthenticatedTemplate>
        <UnauthenticatedTemplate>
                <div className="container-home">
                    <div className="image-home">
                        <img src={Pathta} alt="Pathta" style={{ width: '100%' }} />
                    </div>
                    <div className="text-home">
                        <h1>Bienvenue !</h1>
                        <p>Pathta se positionne comme la cl&eacute; de vo&ucirc;te de l'int&eacute;gration du "No Code" au sein des syst&egrave;mes d'information des entreprises. Con&ccedil;u pour simplifier et s&eacute;curiser l'adoption de cette approche r&eacute;volutionnaire, Pathta offre une plateforme intuitive qui permet aux utilisateurs, techniques comme non techniques, de cr&eacute;er et de d&eacute;ployer des applications sans &eacute;crire une seule ligne de code. Notre outil facilite la transition vers le "No Code", en assurant une int&eacute;gration fluide avec les syst&egrave;mes existants, tout en garantissant la s&eacute;curit&eacute;, la conformit&eacute; et la scalabilit&eacute; des solutions d&eacute;velopp&eacute;es.</p>
                        <p>Avec Pathta, les entreprises peuvent enfin d&eacute;bloquer le plein potentiel du "No Code", en permettant &agrave; leurs &eacute;quipes de concentrer leurs efforts sur l'innovation et la cr&eacute;ation de valeur, plut&ocirc;t que sur la complexit&eacute; technique du d&eacute;veloppement logiciel. Que vous cherchiez &agrave; acc&eacute;l&eacute;rer le d&eacute;veloppement de nouvelles applications, &agrave; optimiser les processus internes ou &agrave; encourager l'innovation ouverte au sein de votre organisation, Pathta est la solution.</p>
                        <p>Embracez l'&egrave;re du "No Code" avec Pathta : simplifiez l'int&eacute;gration, maximisez l'innovation.</p>
                    </div>
                </div>
        </UnauthenticatedTemplate>
      </div>      
    );
  }
}
