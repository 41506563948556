import ProcessingResult from "../../framework/ProcessingResult";

import Delay from "../../tools/Delay";

import DataSourceApi from "../../apis/DataSourceApi";
import DataStructureApi from "../../apis/DataStructureApi";

import GetDatabaseReferencesResult from "../../classes/logic/GetDatabaseReferencesResult";
import DataSourceListDto from "../../classes/dtos/datasourcemodule/DataSourceListDto";

import { StatusUnavailable, StatusLive, StatusError, StatusTimeout, StatusNoData, StatusCache } from "../../classes/const/JobStatus";

const DatabaseReferenceLogic = {

    GetDatabaseReferencesAsync: async function (dataSource: DataSourceListDto, withUpdate: boolean): Promise<ProcessingResult<GetDatabaseReferencesResult>> {

        if (withUpdate) {
            var createRequestDatabasesResult = await DataSourceApi.CreateRequestDatabasesAsync(dataSource.Id);

            if (createRequestDatabasesResult.IsFailed()) {
                return ProcessingResult.Error("Error in CreateRequestDatabasesAsync " + createRequestDatabasesResult.ErrorMessage);
            }
        }

        if (withUpdate && createRequestDatabasesResult.Result.Accepted) {
            var token = createRequestDatabasesResult.Result.Token;
            for (let i = 0; i < 20; i++) {
                var getRequestDatabasesResult = await DataSourceApi.GetRequestDatabasesAsync(token)

                if (getRequestDatabasesResult.IsFailed()) {
                    return ProcessingResult.Error("Error in GetRequestDatabasesAsync " + getRequestDatabasesResult.ErrorMessage);
                }

                if (getRequestDatabasesResult.Result.Status === 4) {
                    return ProcessingResult.Ok(new GetDatabaseReferencesResult(StatusLive, getRequestDatabasesResult.Result.Databases));
                }

                if (getRequestDatabasesResult.Result.Status === 3) {
                    return ProcessingResult.Ok(new GetDatabaseReferencesResult(StatusError, []));
                }

                await Delay.Sleep(500)
            }
            return ProcessingResult.Ok(new GetDatabaseReferencesResult(StatusTimeout, []));
        }
        else {
            var getDatabaseReferencesResult = await DataStructureApi.GetDatabaseReferencesAsync(dataSource.Id);

            if (getDatabaseReferencesResult.IsFailed()) {
                return ProcessingResult.Error("Error in GetDatabaseReferencesAsync " + getDatabaseReferencesResult.ErrorMessage);
            }

            if (getDatabaseReferencesResult.Result.LastUpdateDate == null) {
                return ProcessingResult.Ok(new GetDatabaseReferencesResult(StatusNoData, []));
            }
            else {
                if (!withUpdate) {
                    return ProcessingResult.Ok(new GetDatabaseReferencesResult(StatusCache, getDatabaseReferencesResult.Result.Databases));
                } else {
                    return ProcessingResult.Ok(new GetDatabaseReferencesResult(StatusUnavailable, getDatabaseReferencesResult.Result.Databases));
                }
            }
        }
    }
};

export default DatabaseReferenceLogic;