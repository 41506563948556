class DataSourceCredentialListDto {

    Id: number;
    Name: string;

    constructor(id, name) {
        this.Id = id;
        this.Name = name;
    }
}

export default DataSourceCredentialListDto;