class AuthSession {

    Token: string = "";
    Email: string = "";
    FirstName: string = "";
    LastName: string = "";
    Country: string = "";

    constructor(token: string, email: string) {
        this.Token = token;
        this.Email = email;
    }
}

export default AuthSession;