import TableReferenceIdAndKeyDto from "../dtos/TableReferenceIdAndKeyDto";

class GetTableReferencesResult {
    Status: string;
    Tables: TableReferenceIdAndKeyDto[];

    constructor(status: string, tables: TableReferenceIdAndKeyDto[]) {
        this.Status = status;
        this.Tables = tables;
    }
}

export default GetTableReferencesResult;


