import { Home } from "./components/Home";
import { PathList } from "./components/PathList";
import { PathSynchro } from "./components/PathSynchro";
import { PathReporting } from "./components/PathReporting";
import { Runs } from "./components/Runs";

export const PathsRoute = "paths"
export const PathSynchroRoute = "pathsynchro"
export const PathReportingRoute = "pathreporting"
export const RunsRoute = "runs"

const AppRoutes = [
    {
        index: true,
        element: <Home />
    },
    {
        path: '/' + PathsRoute,
        element: <PathList />
    },
    {
        path: '/' + PathSynchroRoute + '/:id',
        element: <PathSynchro />
    },
    {
        path: '/' + PathReportingRoute + '/:id',
        element: <PathReporting />
    },
    {
        path: '/' + RunsRoute,
        element: <Runs />
    },
];

export default AppRoutes;
