import React, { useCallback, useState, useEffect } from 'react';

import { Table } from 'reactstrap';
import FieldType from '../datasourcemodule/field-type';

import FieldReferenceLogic from '../../businesslayers/datastructuremodule/FieldReferenceLogic';

function FieldsList(props) {

    const currentTableChange = useCallback(async () => {
        props.setFields(null);
    }, [props.tableReference]);

    const fieldsLoad = useCallback(async () => {

        if (props.tableReference != null) {
            if (!props.fields) {

                var getFieldsReferencesResult = await FieldReferenceLogic.GetFieldReferencesAsync(props.dataSource, props.tableReference, !props.fullLoadingInProgress);

                if (getFieldsReferencesResult.IsFailed()) {
                    // ERROR
                }

                var allFields = getFieldsReferencesResult.Result.Fields;

                props.setFields(allFields);
            }
        }
    }, [props.fields, props.dataSource, props.tableReference]);

    useEffect(() => {
        currentTableChange()
    }, [currentTableChange]);

    useEffect(() => {
        fieldsLoad()
    }, [fieldsLoad]);

    if (props.fields === null) {
        return ("");
    }

    return (
        <div width='100%'>
            <Table striped width='100%'>
                <thead>
                    <tr>
                        <th>Field name</th>
                        <th>Field type</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        props.fields.map((field, i) => {
                            return (
                                <tr key={i} field={field}>
                                    <td>{field.Name}</td>
                                    <td><FieldType field={field} dataSource={props.dataSource}/></td>
                                </tr>)
                        })
                    }
                </tbody>
            </Table>
        </div>
    );
}

export default FieldsList;