import React, { Component } from "react";
import Switch from "./../wrapper/switch";
import themes from 'devextreme/ui/themes';

export default class ThemeSwitcher extends Component {

    changeTheme = (state) => {

        themes.ready(() => {
            //if (dataGrid.current && dataGrid.current.instance) {
            //    dataGrid.current.instance.repaint();
            //}
            //if (button.current && button.current.instance) {
            //    button.current.instance.repaint();
            //}
            this.props.ThemeAsChanged('material.orange.dark' === themes.current())
        });

        if (state) {
            themes.current('material.orange.dark');
        } else {
            themes.current('material.orange.light');
        }
    };

    render() {
        return (
            <div className="nav-link" style={{ 'flexDirection': 'row', 'display': 'flex', 'alignItems': 'center', 'textAlign': 'center', 'justifyContent': 'flex-start' }}>
                <div><i className="dx-icon-sun" /></div >
                <div style={{ marginLeft: '8px', marginTop: '1px' }}><Switch onChange={this.changeTheme} /></div>
                <div><i className="dx-icon-moon" /></div>
            </div>
        );
    }
}