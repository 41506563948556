import React, { useState, useEffect, useCallback } from 'react';
import { Nav, NavItem, NavLink, Button, Spinner } from 'reactstrap';
import { useNavigate, useParams } from "react-router-dom";
import { AuthenticatedTemplate, UnauthenticatedTemplate } from '@azure/msal-react';

import ScheduleDto from '../frontend/classes/dtos/schedulermodule/ScheduleDto';

import PathReportingApi from "./../frontend/apis/PathReportingApi";

import { PathReportingSource } from './pathreporting/PathReportingSource';
import { PathReportingMapping } from './pathreporting/PathReportingMapping';
//import { PathReportingStarter } from './pathreporting/PathReportingStarter';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleLeft } from '@fortawesome/free-solid-svg-icons'

import Input from './../components/wrapper/input';

import '../common.css';
import './pathreporting/pathreporting.css';

const PathReportingTab = {
    Source: 1,
    Mapping: 2,
    Preview: 3
}

export function PathReporting() {

    let params = useParams();

    const navigate = useNavigate();

    // Data
    const [currentPathId] = useState(parseInt(params.id));

    const [pathName, setPathName] = useState("");

    const [mapping, setMapping] = useState(null);

    const [currentDataSource1, setCurrentDataSource1] = useState(null);

    const [currentDatabaseReference1, setCurrentDatabaseReference1] = useState(null);

    const [currentTableReference1, setCurrentTableReference1] = useState(null);

    const [fields1, setFields1] = useState(null);

    const [fullLoadingInProgress, setFullLoadingInProgress] = useState(true);

    const [scheduleIsActive, setScheduleIsActive] = useState(false);
    const [scheduleType, setScheduleType] = useState(0);
    const [scheduleInterval, setScheduleInterval] = useState(15);
    const [scheduleTime, setScheduleTime] = useState("08:00");

    useEffect(() => {
        if (mapping != null) {

            if ((mapping.Source.TableReferenceId === 0 || (mapping.Source.TableReferenceId > 0 && fields1 != null))) {
                setFullLoadingInProgress(false);
            }
        }

    }, [mapping, fields1])

    function changePathName(value) {

        setPathName(value);
    }

    const fetchResult = useCallback(async () => {
        if (!mapping) {
            var mappingResult = await PathReportingApi.GetPathReportingDetailAsync(currentPathId);

            if (mappingResult.IsFailed()) {
                alert("Error GetPathReportingDetailAsync GetPathSynchroDetailAsync : " + mappingResult.ErrorMessage);
                return;
            }

            setPathName(mappingResult.Result.Name)
            setMapping(mappingResult.Result);
            setScheduleIsActive(mappingResult.Result.Schedule.IsActive)
            setScheduleType(mappingResult.Result.Schedule.Type)
            setScheduleInterval(mappingResult.Result.Schedule.Interval);
            setScheduleTime(mappingResult.Result.Schedule.Time);
        }
    }, [currentPathId, mapping]);

    useEffect(() => {
        fetchResult()
    }, [fetchResult]);

    // Actions

    const backToList = async () => {
        navigate('/Paths');
    }

    const savePath = async () => {

        let schedule = new ScheduleDto(scheduleIsActive, scheduleType, scheduleInterval, scheduleTime);

        var filtered = [];
        mapping.Mappings.forEach((element) => {
            if (fields1.find(e => e.Id === element.FieldReferenceId)) {
                filtered.push(element);
            }
        });

        mapping.Mappings = filtered;

        let updateResult = await PathReportingApi.UpdatePathReportingDetailAsync(currentPathId, pathName, mapping.Mappings, currentDataSource1?.Id ?? null, currentDatabaseReference1?.Id ?? null, currentTableReference1?.Id ?? null, schedule, true);

        if (updateResult.IsFailed()) {
            alert("Error during UpdatePathReportingDetailAsync : " + updateResult.ErrorMessage);
            return;
        }
    }

    // UX
    const [currentTab, setCurrentTab] = useState(PathReportingTab.Source);

    function getActive(tab) {
        switch (tab) {
            default:
                return currentTab === tab;
        }
    }

    function getDisabled(tab) {
        switch (tab) {
            case PathReportingTab.Source:
                return false;
            case PathReportingTab.Mapping:
                return currentTableReference1 === null;
            case PathReportingTab.Starter:
                return currentTableReference1 === null;
            default:
                return true;
        }
    }

    function getClassName(tab) {
        if (getActive(tab)) {
            return "";
        }
        else {
            return "nowcode-hidden";
        }
    }

    function click(tab) {
        if (tab !== currentTab) {
            setCurrentTab(tab);
        }
    }

    function getClassNameForLoadingContent(inProgress) {
        if (inProgress) {
            return 'path-reporting nowcode-hidden';
        } else {
            return 'path-reporting';
        }
    }

    function getClassNameForLoadingSpinner(inProgress) {
        if (inProgress) {
            return 'spinner';
        } else {
            return 'spinner nowcode-hidden';
        }
    }

    return (
        <div>
            <AuthenticatedTemplate>
                <Spinner color="primary" className={getClassNameForLoadingSpinner(fullLoadingInProgress)}>Chargement en cours</Spinner>
                <div className={getClassNameForLoadingContent(fullLoadingInProgress)}>
                    <div className='nowcode-internal-block nowcode-internal-space-bottom start-line'>
                        <button className="nowcode-internal-space-right btn-icon" onClick={backToList}><FontAwesomeIcon icon={faAngleLeft} /></button>
                        <div style={{ width:'5px'}}></div>
                        <Input className='nowcode-internal-space-right' value={pathName} onChange={e => changePathName(e.target.value)} />
                        <Button color="primary" className='nowcode-internal-space-right' onClick={savePath}>Save</Button>
                    </div>
                    <div className='nowcode-internal-block nowcode-internal-space-bottom'>
                        <Nav justified pills>
                            <NavItem>
                                <NavLink active={getActive(PathReportingTab.Source)} disabled={getDisabled(PathReportingTab.Source)} onClick={() => click(PathReportingTab.Source)} href="#">Source</NavLink>
                            </NavItem>
                            <NavItem>
                                <NavLink active={getActive(PathReportingTab.Mapping)} disabled={getDisabled(PathReportingTab.Mapping)} onClick={() => click(PathReportingTab.Mapping)} href="#">Mapping</NavLink>
                            </NavItem>
                            {/*<NavItem>*/}
                            {/*    <NavLink active={getActive(PathReportingTab.Starter)} disabled={getDisabled(PathReportingTab.Starter)} onClick={() => click(PathReportingTab.Starter)} href="#">Schedule</NavLink>*/}
                            {/*</NavItem>*/}
                        </Nav>
                    </div>
                    <div className={getClassName(PathReportingTab.Source)}>
                        <PathReportingSource
                            currentDataSource={currentDataSource1}
                            setCurrentDataSource={setCurrentDataSource1}
                            currentDatabaseReference={currentDatabaseReference1}
                            setCurrentDatabaseReference={setCurrentDatabaseReference1}
                            currentTableReference={currentTableReference1}
                            setCurrentTableReference={setCurrentTableReference1}
                            fields={fields1}
                            setFields={setFields1}
                            mapping={mapping}
                            fullLoadingInProgress={fullLoadingInProgress}
                        />
                    </div>
                    <div className={getClassName(PathReportingTab.Mapping)}>
                        <PathReportingMapping
                            currentDataSource1={currentDataSource1}
                            currentDatabaseReference1={currentDatabaseReference1}
                            currentTableReference1={currentTableReference1}
                            fields1={fields1}
                            mapping={mapping}
                            setMapping={setMapping}
                            fullLoadingInProgress={fullLoadingInProgress}
                        />
                    </div>
                    {/*<div className={getClassName(PathReportingTab.Starter)}>*/}
                    {/*    <PathReportingStarter*/}
                    {/*        scheduleIsActive={scheduleIsActive}*/}
                    {/*        setScheduleIsActive={setScheduleIsActive}*/}
                    {/*        scheduleType={scheduleType}*/}
                    {/*        setScheduleType={setScheduleType}*/}
                    {/*        scheduleInterval={scheduleInterval}*/}
                    {/*        setScheduleInterval={setScheduleInterval}*/}
                    {/*        scheduleTime={scheduleTime}*/}
                    {/*        setScheduleTime={setScheduleTime}*/}
                    {/*    />*/}
                    {/*</div>*/}
                </div>
            </AuthenticatedTemplate>
            <UnauthenticatedTemplate>
            </UnauthenticatedTemplate>
        </div>
    );
}