import React from 'react';
import { useParams, useNavigate } from "react-router-dom";
import { AuthenticatedTemplate, UnauthenticatedTemplate } from '@azure/msal-react';

import { Paths } from "./Paths";

import PathType from '../frontend/const/pathmodule/PathType';

import { PathSynchroRoute, PathReportingRoute } from '../AppRoutes';

import './Home.css';

export function PathList() {

    var params = useParams();

    var navigate = useNavigate();

    function OpenPath(pathId, pathTypeId) {
        switch (pathTypeId) {

            case PathType.DataSynchronization:
                navigate("/" + PathSynchroRoute + "/" + pathId);
                break;
            case PathType.Reporting:
                navigate("/" + PathReportingRoute + "/" + pathId);
                break;
            default:
        }
    }

    return (
        <div>
            <AuthenticatedTemplate>
                <Paths params={params} openPath={OpenPath} />
            </AuthenticatedTemplate>
            <UnauthenticatedTemplate>
            </UnauthenticatedTemplate>
        </div>
    );
}
