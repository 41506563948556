import React, { useState } from 'react';

import { Tooltip as ReactTooltip } from 'reactstrap';

function Tooltip(props) {

    const [tooltipOpen, setTooltipOpen] = useState(false);
    const toggle = () => setTooltipOpen(!tooltipOpen);
    const [id] = useState("ID" + Math.random().toString(36).substr(2, 9));

    var style = {};

    if (props.width != null) {
        style.width = props.width;
    }

    return (
        <div>
            <div id={id}>
                {props.children}
            </div>
            <ReactTooltip
                {...props}
                isOpen={tooltipOpen}
                target={id}
                toggle={toggle}>
                {props.content}
            </ReactTooltip>
        </div>
    );
}

export default Tooltip