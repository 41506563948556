import React from 'react';

import { Form, FormGroup, Col, Label, Input } from 'reactstrap';

import Box from '../wrapper/box';
import Switch from '../wrapper/switch';

import ScheduleTypeSelect from "../../frontend/components/schedulermodule/schedule-type-select.js";

import '../../common.css';
import './pathsynchro.css';

export function PathSynchroStarter(props) {

    const changeScheduleIsActive = (value) => {
        props.setScheduleIsActive(value);
    }

    function getClassName(scheduleType) {

        if (props.scheduleType === scheduleType) {
            return "nowcode-internalline";
        }
        else {
            return "nowcode-hidden";
        }
    }

    return (
        <div className='nowcode-columns path-height'>
            <div className='path-synchro-50p path-synchro-mr nowcode-internal-block'>
                <Box width='100%'>
                    <Form>
                        <FormGroup className='nowcode-internalline'>
                            <Switch label="Active" value={props.scheduleIsActive} onChange={changeScheduleIsActive}></Switch>
                        </FormGroup>
                        <FormGroup className='nowcode-internalline'>
                            <Label sm={2}>Type</Label>
                            <Col sm={10}>
                                <ScheduleTypeSelect scheduleType={props.scheduleType} setScheduleType={props.setScheduleType} />
                            </Col>
                        </FormGroup>
                        <FormGroup className={getClassName(1)} row>
                            <Label sm={2}>Minutes</Label>
                            <Col sm={10}>
                                <Input
                                    name="minutes"
                                    placeholder="minutes"
                                    type="number"
                                    value={props.scheduleInterval}
                                    onChange={e => props.setScheduleInterval(e.target.value)}
                                />
                            </Col>
                        </FormGroup>
                        <FormGroup className={getClassName(2)}>
                            <Label sm={2}>Time</Label>
                            <Col sm={10}>
                                <Input
                                    name="time"
                                    placeholder="time"
                                    type="time"
                                    value={props.scheduleTime}
                                    onChange={e => props.setScheduleTime(e.target.value)}
                                />
                            </Col>
                        </FormGroup>
                    </Form>
                </Box>
            </div>
            <div className='path-synchro-50p nowcode-internal-block'>???</div>
        </div>
    );
}
