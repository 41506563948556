import React, { useCallback, useState, useEffect } from 'react';

import Box from './../../components/wrapper/box';
import Button from './../../components/wrapper/button';
import Label from './../../components/wrapper/label';
import Progress from './../../components/wrapper/progress';

import { Alert } from 'reactstrap';

import PathSynchroLogic from '../../frontend/businesslayers/pathsynchromodule/PathSynchroLogic';

import '../../common.css';
import './pathsynchro.css';

export function PathSynchroRuns(props) {

    const [syncToDo, setSyncToDo] = useState(false);
    const [progress, setProgress] = useState(0);
    const [syncDone, setSyncDone] = useState(false);

    const [errorMessage, setErrorMessage] = useState(null);

    const synchro = useCallback(async () => {
        if (syncToDo === true) {
            setErrorMessage(null);

            let result = await PathSynchroLogic.PathSynchroAsync(props.currentPathId, (progress) => { setProgress(progress); });

            if (result.IsFailed()) {
                alert(result.ErrorMessage);
            }

            setErrorMessage(result.Result);
            setSyncDone(true);
            setSyncToDo(false);
        }
    }, [props.currentPathId, syncToDo]);

    useEffect(() => {
        synchro()
    }, [synchro]);

    const synchroAction = async () => {
        setSyncDone(false);
        setProgress(0);
        setSyncToDo(true);
    }

    return (
        <div className='nowcode-columns path-height'>
            <div className='path-synchro-50p path-synchro-mr nowcode-internal-block'>
                <Box className='nowcode-internalline'>
                    <Button marginLeft={1} onClick={synchroAction} disabled={syncToDo || !props.syncAvailable}>SYNCHRO</Button>
                    {!props.syncAvailable && <Label marginLeft='5px' color='red'>The keys was not setup correctly</Label>}
                    {(syncToDo || syncDone) && <Progress color="primary" value={progress} width='100%' marginLeft='5px' />}
                </Box>
                {(errorMessage != null && errorMessage !== "") && <Alert color="danger">Error during execution : {errorMessage}</Alert>}
            </div>
            <div className='path-synchro-50p nowcode-internal-block'>???</div>
        </div>
    );
}
