import React from 'react';

import PathReportingFields from './PathReportingFields.js';

import '../../common.css';
import './pathreporting.css';

export function PathReportingMapping(props) {

    return (
        <div className='nowcode-columns path-height'>
            <div className='path-reporting-50p path-reporting-mr nowcode-internal-block'>
                <PathReportingFields
                    dataSourceSource={props.currentDataSource1}

                    databaseReferenceSource={props.currentDatabaseReference1}

                    tableReferenceSource={props.currentTableReference1}

                    fieldsSource={props.fields1}

                    setCheckToDo={props.setCheckToDo}

                    mapping={props.mapping}
                    setMapping={props.setMapping}
                    fullLoadingInProgress={props.fullLoadingInProgress}
                />
            </div>
            <div className='path-reporting-50p nowcode-internal-block'></div>
        </div>
    );
}
