import React, { Component } from 'react';
import withRouter from './withRouter';
import { Collapse, Navbar, NavbarBrand, NavbarToggler, NavItem } from 'reactstrap';
import { Link } from 'react-router-dom';
import { AuthenticatedTemplate, UnauthenticatedTemplate } from '@azure/msal-react';
import { NavLogin } from './api-authorization/NavLogin';
import { NavLogout } from './api-authorization/NavLogout';
import ThemeSwitcher from "./home/ThemeSwitcher";
import Tabs from 'devextreme-react/tabs';
import PathtaBlack from "./asset/LogoBlack.svg";
import PathtaWhite from "./asset/LogoWhite.svg";
import './NavMenu.css';

class NavMenu extends Component {
    static displayName = NavMenu.name;

    constructor(props) {
        super(props);

        this.toggleNavbar = this.toggleNavbar.bind(this);
        this.toggleMenu = this.toggleMenu.bind(this);

        this.state = {
            collapsed: true,
            dropdownOpen: false,
            darkMode: false,
            selectedPage: null,
            pages: [
                {
                    id: "/paths",
                    text: 'Paths',
                },
                {
                    id: "/runs",
                    text: 'Runs',
                },
                {
                    id: "/settings",
                    text: 'Settings',
                }]
        };

        this.onSelectionChanged = this.onSelectionChanged.bind(this);
    }

    componentDidMount() {
        if (this.props.isAuthenticated) {
            var page = this.state.pages.find((e) => e.id === this.props.router.location.pathname);
            if (page === undefined) {
                page = this.state.pages.find((e) => e.id === "/paths");
            }
            this.setState({ selectedPage: page });
        }
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.props.isAuthenticated) {
            var page = this.state.pages.find((e) => e.id === this.props.router.location.pathname);
            if (page === undefined) {
                page = this.state.pages.find((e) => e.id === "/paths");
            }

            if (page !== this.state.selectedPage) {
                this.setState({ selectedPage: page });
                this.props.router.navigate(page.id);
            }
        }
    }

    toggleNavbar() {
        this.setState({
            collapsed: !this.state.collapsed
        });
    }

    toggleMenu() {
        this.setState({
            dropdownOpen: !this.state.dropdownOpen
        });
    }

    onSelectionChanged = (e) => {
        var selection = e.selectedItem || e.addedItems[0];

        if (selection === undefined) {
            return;
        }
        var page = this.state.pages.find((e) => e.id === selection.id);
        this.setState({ selectedPage: page });
        this.props.router.navigate(selection.id);
    }

    render() {
        return (
            <header>
                <Navbar className="navbar-expand-sm navbar-toggleable-sm ng-white border-bottom box-shadow mb-3" container light>
                    <NavbarBrand tag={Link} to="/">
                        {(this.state.darkMode) ?
                            <img src={PathtaWhite} alt="Pathta" style={{ maxHeight: 32 }} /> :
                            <img src={PathtaBlack} alt="Pathta" style={{ maxHeight: 32 }} />}
                    </NavbarBrand>
                    <NavbarToggler onClick={this.toggleNavbar} className="mr-2" />
                    <Collapse className="d-sm-inline-flex flex-sm-row-reverse" isOpen={!this.state.collapsed} navbar>
                        <ul className="navbar-nav flex-grow">
                            <AuthenticatedTemplate>
                                <Tabs
                                    id="withText"
                                    width={'300px'}
                                    rtlEnabled={false}
                                    dataSource={this.state.pages}
                                    orientation={'horizontal'}
                                    stylingMode={'secondary'}
                                    selectedItem={this.state.selectedPage}
                                    onSelectionChanged={this.onSelectionChanged}
                                />
                                <NavLogout />
                                <ThemeSwitcher ThemeAsChanged={(e) => this.setState({ darkMode: e })} />
                            </AuthenticatedTemplate>
                            <UnauthenticatedTemplate>
                                <NavItem>
                                    <NavLogin />
                                </NavItem>
                            </UnauthenticatedTemplate>
                        </ul>
                    </Collapse>
                </Navbar>
            </header>
        );
    }
}

export default withRouter(NavMenu);