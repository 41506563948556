import React from "react";

import { Input, Alert } from 'reactstrap';

import FormField from './../../../components/wrapper/formfield';
import DialogBody from "../framework/dialog-body.js";

function DataSourceCredentialDialogBody(props) {

    const updateValues = function (name, value) {
        const updatedValues = { ...props.values, [name]: value };
        props.setValues(updatedValues);
    }

    return (
        <DialogBody>
            <FormField label="Name">
                <Input value={props.name} onChange={e => props.setName(e.target.value)} invalid={props.invalid} />
            </FormField>
            {
                props.authentications[0].Details.map((e, index) => (
                    <FormField key={index} label={e.Label}>
                        <Input value={props.values[e.Name]} onChange={event => updateValues(e.Name, event.target.value)} invalid={props.invalid} />
                    </FormField>
                ))
            }
            <Alert color="danger" isOpen={props.invalid}>
                Credential is invalid !
            </Alert>
        </DialogBody>);
}

export default DataSourceCredentialDialogBody;