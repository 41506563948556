class GetRequestTestDataSourceResult {
    Status: number;
    Ok: boolean;
    Error: string;

    constructor(status: number, ok: boolean, error: string) {
        this.Status = status;
        this.Ok = ok;
        this.Error = error;
    }
}

export default GetRequestTestDataSourceResult;
