import React from 'react'

import { Progress as ReactProgress } from 'reactstrap';

function Progress(props) {

    var style = {};

    if (props.marginLeft != null) {
        style.marginLeft = props.marginLeft;
    }

    if (props.marginRight != null) {
        style.marginRight = props.marginRight;
    }

    if (props.width != null) {
        style.width = props.width;
    }

    return (
        <ReactProgress color="primary" value={props.value} style={style} />
    );
}

export default Progress