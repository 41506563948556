import FieldTypeReferenceDto from "./FieldTypeReferenceDto";

class FieldReferenceIdAndKeyDto {
    Id: number;
    SourceKey: string;
    DatasetKey: string;
    Name: string;
    Type: FieldTypeReferenceDto;

    constructor(
        id: number,
        sourceKey: string,
        datasetKey: string,
        name: string,
        type: FieldTypeReferenceDto
    ) {
        this.Id = id;
        this.SourceKey = sourceKey;
        this.DatasetKey = datasetKey;
        this.Name = name;
        this.Type = type;
    }

    get Label(): string {
        return `${this.Name} (${this.Type.OriginTypeLabel ?? "???"})`;
    }
}

export default FieldReferenceIdAndKeyDto;