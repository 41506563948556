import React from 'react';
import { useMsal } from '@azure/msal-react';
import { NavLink } from 'reactstrap';
import { apiScopes } from '../../AuthConfig';

export const NavLogin = () => {
    const { instance } = useMsal();

    const loginRequest = {
        scopes: apiScopes
    };

    const initializeSignIn = () => {
        instance.loginRedirect(loginRequest);
    };

    return (
        <NavLink className="text-dark" onClick={initializeSignIn} href="#">Sign in</NavLink>
    );
};
