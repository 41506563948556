import React, { useState } from "react";

import SelectKeyNumber from '../framework/selectkeynumber';
import TextButton from './../../../components/wrapper/textbutton';
import Tooltip from "../framework/tooltip.js";
import Box from './../../../components/wrapper/box';
import Label from './../../../components/wrapper/label';

function CustomerAgentSelect(props) {

    const [dialogIsOpen, setDialogIsOpen] = useState(false);

    const openDialog = () => { alert("TODO"); } // "setDialogIsOpen(true);

    if (props.customerAgents == null)
    {
        return (<div>Chargement en cours 🚀</div>);
    }

    var customerAgentsUi = props.customerAgents.map(customerAgent => (
        { value: customerAgent.Id, label : customerAgent.Name }
    ));

    customerAgentsUi.unshift({ value: -1, label : "Choose an agent" });

    var currentCustomerAgentId = props.customerAgent != null ? props.customerAgent.Id : -1;

    return (
        <Box width='100%'>
            <Box className='nowcode-selectline'>
                {props.customerAgents.length === 0 ?
                    <Box width='100%'><Label alignSelf='center' margin={0}>You should create your first agent</Label></Box> :
                    <SelectKeyNumber
                        options={customerAgentsUi}
                        value={currentCustomerAgentId}
                        onChange={Id => props.setCustomerAgent(props.customerAgents.find((customerAgent) => customerAgent.Id === Id) ?? null)}
                    />}
                <Tooltip content="Create a datasource">
                    <TextButton margin={1} icon="plus" onClick={openDialog} />
                </Tooltip>
            </Box>
        </Box>
    );
}

export default CustomerAgentSelect;
