import React, { useState } from 'react';

import Box from './../wrapper/box';
import Switch from './../wrapper/switch';

import { Table } from 'reactstrap';

import PathSynchroField from './PathSynchroField.js';

function PathSynchroFields(props) {

    const [onlyCompatibleData, setOnlyCompatibleData] = useState(true);

    const changeOnlyCompatibleData = (value) => {
        setOnlyCompatibleData(value);
    }

    if (props.fieldsSource == null || props.fieldsTarget == null)
    {
        return (<Box width='100%'></Box>);
    }

    return (
        <Box width='100%'>
            <Box className='nowcode-internalline'>
                <Switch label="Show only comptatible fields" value={onlyCompatibleData} onChange={changeOnlyCompatibleData}></Switch>
            </Box>
            {
                <div width='100%'>
                    <Table striped width='100%'>
                        <thead>
                            <tr>
                                <th>Key</th>
                                <th>Source</th>
                                <th>Target</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                props.fieldsTarget.map((field, i) => {
                                    return (<PathSynchroField key={i} index={i} field={field} fields={props.fieldsSource} mapping={props.mapping} setMapping={props.setMapping} targetFieldReferenceId={field.Id} onlyCompatibleData={onlyCompatibleData} setCheckToDo={props.setCheckToDo} dataSource={props.dataSourceTarget}></PathSynchroField>)
                                })
                            }
                        </tbody>
                    </Table>
                </div>
            }
        </Box>
    );
}

export default PathSynchroFields;