import DatabaseReferenceIdAndKeyDto from "../dtos/DatabaseReferenceIdAndKeyDto";

class GetDatabaseReferencesResult {
    Status: string;
    Databases: DatabaseReferenceIdAndKeyDto[];

    constructor(status: string, databases: DatabaseReferenceIdAndKeyDto[]) {
        this.Status = status;
        this.Databases = databases;
    }
}

export default GetDatabaseReferencesResult;


