import Settings from "./Settings";
import { AuthToken } from "./AuthToken";

import ProcessingResult from "../framework/ProcessingResult";

import UserSession from "./UserSession";

import GetPathReportingDetailResult from "../classes/api/result/pathreportingmodule/GetPathReportingDetailResult";
import UpdatePathReportingDetailResult from "../classes/api/result/pathreportingmodule/UpdatePathReportingDetailResult";
import EnsurePersonalAccessTokenResult from "../classes/api/result/pathreportingmodule/EnsurePersonalAccessTokenResult";

import TableMappingReadDto from "../classes/dtos/pathcommonmodule/TableMappingReadDto";
import PathReportingMappingDto from "../classes/dtos/pathreportingmodule/PathReportingMappingDto";
import ScheduleDto from "../classes/dtos/schedulermodule/ScheduleDto";

const PathReportingApi = {

    GetPathReportingDetailAsync: async function (pathId: number): Promise<ProcessingResult<GetPathReportingDetailResult>> {
        let authSession = await AuthToken.get();

        let customerId = await UserSession.EnsureAsync(authSession.Token);

        if (customerId === -1) {
            return ProcessingResult.Error("Unhautorized");
        }

        const response = await fetch(Settings.api_url + "/PathReporting/GetPathReportingDetail?customerId=" + customerId.toString() + "&pathId=" + pathId.toString(), {
            method: "Get",
            mode: "cors",
            cache: "no-cache",
            credentials: 'include',
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + authSession.Token
            }
        });

        if (response.status === 200) {
            var data = await response.json();

            var source = data.source != null ? new TableMappingReadDto(data.source.dataSourceId, data.source.dataSourceLabel, data.source.databaseReferenceId, data.source.databaseReferenceLabel, data.source.tableReferenceId, data.source.tableReferenceLabel) : null;
            var mappings = data.mappings.map((e: any) => new PathReportingMappingDto(e.fieldReferenceId, e.isQueryable, e.isRequired, e.defaultValue, e.isExposed));
            var schedule = new ScheduleDto(data.schedule.isActive, data.schedule.scheduleType, data.schedule.interval, data.schedule.time);
            return ProcessingResult.Ok(new GetPathReportingDetailResult(data.id, data.name, source, mappings, schedule));
        }

        return ProcessingResult.Error("Error = " + response.status.toString());
    },

    UpdatePathReportingDetailAsync: async function (pathId: number, name: string, mappings: PathReportingMappingDto[], sourceDataSourceId: number | null, sourceDatabaseReferenceId: number | null, sourceTableReferenceId: number | null, schedule: ScheduleDto, publish: boolean): Promise<ProcessingResult<UpdatePathReportingDetailResult>>
    {
        let authSession = await AuthToken.get();

        let customerId = await UserSession.EnsureAsync(authSession.Token);

        if (customerId === -1) {
            return ProcessingResult.Error("Unhautorized");
        }

        const response = await fetch(Settings.api_url + "/PathReporting/UpdatePathReportingDetail", {
            method: "Post",
            mode: "cors",
            cache: "no-cache",
            credentials: 'include',
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + authSession.Token
            },
            body: JSON.stringify({
                'customerId': customerId,
                'pathId': pathId,
                'name': name,
                'DataSourceId': sourceDataSourceId,
                'DatabaseReferenceId': sourceDatabaseReferenceId,
                'TableReferenceId': sourceTableReferenceId,
                'mappings': mappings.map((e) => { return { fieldReferenceId: e.FieldReferenceId, isQueryable: e.IsQueryable, isRequired: e.IsRequired, defaultValue: e.DefaultValue, isExposed: e.IsExposed } }),
                'schedule': {
                    'isActive': schedule.IsActive,
                    'scheduleType': schedule.Type,
                    'interval': schedule.Interval,
                    'time': schedule.Time
                },
                'Publish': publish
            })
        });

        if (response.status === 200)
        {
            var data = await response.json();
            return ProcessingResult.Ok(new UpdatePathReportingDetailResult(data.id));
        }

        return ProcessingResult.Error("Error = " + response.status.toString());
    },

    EnsurePersonalAccessTokenAsync: async function (): Promise<ProcessingResult<EnsurePersonalAccessTokenResult>> {
        let authSession = await AuthToken.get();

        let customerId = await UserSession.EnsureAsync(authSession.Token);

        if (customerId === -1) {
            return ProcessingResult.Error("Unhautorized");
        }

        const response = await fetch(Settings.api_url + "/PathReporting/EnsurePersonalAccessToken", {
            method: "Post",
            mode: "cors",
            cache: "no-cache",
            credentials: 'include',
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + authSession.Token
            },
            body: JSON.stringify({
                'customerId': customerId
            })
        });

        if (response.status === 200) {
            var data = await response.json();
            return ProcessingResult.Ok(new EnsurePersonalAccessTokenResult(data.personalAccessToken));
        }

        return ProcessingResult.Error("Error = " + response.status.toString());
    }
};

export default PathReportingApi;