export const FieldTypeHelper = {
    GetColor: function (fieldType) {
        if (fieldType.Type > 0) {
            return "success";
        } else {
            return "secondary"
        }
    }
}

