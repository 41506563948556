import React, { Component } from 'react';
import { Button } from 'reactstrap';

import PathApi from '../frontend/apis/PathApi';

import PathGrid from '../frontend/components/pathmodule/path-grid';
import PathDialog from '../frontend/components/pathmodule/path-dialog';

import './Home.css';
import '../common.css';

export class Paths extends Component {
    static displayName = Paths.name;

    constructor(props) {
        super(props);
        this.state = {
            paths : null,
            dialogIsOpen: false,
            listToRefresh: false
        };
    }

    refresh() {
        if (!this.state.paths || this.state.listToRefresh) {
            PathApi.GetPathsAsync()
                .then(allPaths => {

                    if (allPaths.IsFailed()) {
                        // TODO (Logout)
                    }
                    else {
                        this.setState(
                            {
                                paths: allPaths.Result,
                                listToRefresh: false
                            });
                    }
                });
        }
    }

    componentDidMount() {
        this.refresh();
    }

    componentDidUpdate(prevProps, prevState) {
        this.refresh();
    }

    OpenDialog = () => {
        this.setState({ dialogIsOpen: true });
    }

    CloseDialog = (pathId, pathTypeId) => {
        this.setState({ dialogIsOpen: false, listToRefresh: false });
        if (pathId != null && pathTypeId != null) {
            this.props.openPath(pathId, pathTypeId);
        }
    }

    OpenPath = (pathId, pathTypeId) => {
        this.props.openPath(pathId, pathTypeId);
    };

    render() {
        return (
            <div>
                <div className='add-right-line'>
                    <Button color="primary" onClick={this.OpenDialog}>Add new path</Button>
                </div>
                <PathGrid paths={this.state.paths} listToRefresh={this.state.listToRefresh} openPath={this.OpenPath} />
                {this.state.dialogIsOpen ? (<PathDialog closeDialog={this.CloseDialog} />) : ("")}
            </div>
        );
    }
}
