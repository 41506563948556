class PathSynchroMappingDto {
    IsKey: boolean;
    SourceFieldReferenceId: number;
    TargetFieldReferenceId: number;
    TransformationType: number;
    TransformationParameters: string;

    constructor(
        isKey: boolean,
        sourceFieldReferenceId: number,
        targetFieldReferenceId: number,
        transformationType: number,
        transformationParameters: string
    ) {
        this.IsKey = isKey;
        this.SourceFieldReferenceId = sourceFieldReferenceId;
        this.TargetFieldReferenceId = targetFieldReferenceId;
        this.TransformationType = transformationType;
        this.TransformationParameters = transformationParameters;
    }
}

export default PathSynchroMappingDto;
