import { useNavigate, useLocation, useParams } from 'react-router-dom';
import { useIsAuthenticated } from '@azure/msal-react';

function withRouter(Component) {
    function ComponentWithRouterProp(props) {
        let navigate = useNavigate();
        let location = useLocation();
        let params = useParams();
        let isAuthenticated = useIsAuthenticated();
        return (
            <Component
                {...props}
                router={{ navigate, location, params }}
                isAuthenticated={isAuthenticated}
            />
        );
    }

    return ComponentWithRouterProp;
}

export default withRouter;
