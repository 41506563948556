﻿class DataSourceCommonDto {
    public Id: number;
    public Name: string;
    public Type: number;
    public ConnectorType: number;
    public CustomerAgentId: number;

    constructor(
        id: number,
        name: string,
        type: number,
        connectorType: number,
        customerAgentId: number
    ) {
        this.Id = id;
        this.Name = name;
        this.Type = type;
        this.ConnectorType = connectorType;
        this.CustomerAgentId = customerAgentId;
    }
}

export default DataSourceCommonDto;
