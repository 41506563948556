import DataSourceCommonDto from "./DataSourceCommonDto";

class DataSourceWithCredentialDto extends DataSourceCommonDto {
    DataSourceCredentialId: number;

    constructor(
        id: number,
        name: string,
        type: number,
        connectorType: number,
        customerAgentId: number,
        dataSourceCredentialId: number
    ) {
        super(id, name, type, connectorType, customerAgentId);
        this.DataSourceCredentialId = dataSourceCredentialId;
    }
}

export default DataSourceWithCredentialDto;
