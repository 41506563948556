import React, { useCallback, useState, useEffect } from 'react';

import { DataGrid, Scrolling, Column } from 'devextreme-react/data-grid';
import CustomStore from 'devextreme/data/custom_store'

import RowsLogic from './../../frontend/businesslayers/datasourcemodule/RowsLogic.js';

import { StatusUnavailable, StatusError, StatusTimeout } from "./../../frontend/classes/const/JobStatus";

import { Alert } from 'reactstrap';

export function DataViewer(props) {

    const MaximumRowCount = 10;

    const [rows, setRows] = useState(null);

    const [errorMessage, setErrorMessage] = useState(null);
    const [warningMessage, setWarningMessage] = useState(null);

    const sourceRowsLoad = useCallback(async () => {

        setErrorMessage(null);
        setWarningMessage(null);

        if (props.currentDataSource != null && props.currentTableReference != null && props.fields != null) {

            var getRowsResult = await RowsLogic.GetRowsAsync(props.currentDataSource.Id, props.currentTableReference.Id, props.fields.map(e => e.Id), MaximumRowCount);

            if (getRowsResult.IsFailed()) {
                setErrorMessage(getRowsResult.ErrorMessage);
                setRows(null);
                return;
            }

            if (getRowsResult.Result.Status === StatusError) {
                setErrorMessage("Data source is errored");
                setRows(null);
                return;
            }

            if (getRowsResult.Result.Status === StatusTimeout) {
                setErrorMessage("Data source timeout");
                setRows(null);
                return;
            }

            if (getRowsResult.Result.Status === StatusUnavailable) {
                setWarningMessage("Data source is offline");
                setRows(null);
                return;
            }

            setRows(getRowsResult.Result.Rows);
        } else {
            setRows(null);
        }
    }, [props.currentDataSource, props.currentTableReference, props.fields]);

    useEffect(() => {
        sourceRowsLoad()
    }, [sourceRowsLoad]);

    const customDataSource = new CustomStore({
        key: '_RowId',
        load: () => {
            return rows;
        }
    });

    function getColumnType(type) {
        switch (type.Type) {
            case 2: return "number";
            case 7: return "date";
            case 8: return "datetime";
            default: return "string";
        }
    }

    if (errorMessage != null) {
        return (<Alert color="danger">{errorMessage}</Alert>);
    };

    if (warningMessage != null) {
        return (<Alert color="warning">{warningMessage}</Alert>);
    };

    return (
        <div>
            <DataGrid id="dataGrid"
                dataSource={customDataSource}
                allowColumnReordering={true}
                allowColumnResizing={true}
                columnAutoWidth={true}>
                <Scrolling columnRenderingMode="virtual" />
                {props.fields != null && props.fields.map((e, index) => <Column
                    key={index}
                    dataField={e.Id.toString()}
                    dataType={getColumnType(e.Type)}
                    caption={e.Name} />)}
            </DataGrid>
        </div>);
}