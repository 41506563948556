import React from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus } from '@fortawesome/free-solid-svg-icons'
import { faPencil } from '@fortawesome/free-solid-svg-icons'
import { faTrash } from '@fortawesome/free-solid-svg-icons'

function TextButton(props) {

    var style = {};

    if (props.margin != null) {
        style.margin = props.margin;
    }

    var icon = null;

    if (props.icon === "plus") { icon = faPlus;  }
    if (props.icon === "edit") { icon = faPencil; }
    if (props.icon === "trash") { icon = faTrash; }

    return (
        <div>
            <button className="btn-icon" style={style} onClick={props.onClick} disabled={props.disabled}><FontAwesomeIcon icon={icon} /></button>
        </div>
    );
}

export default TextButton