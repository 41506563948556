import React, { useEffect, useState } from 'react';

//import { Box, Label, Select, Switch } from '@airtable/blocks/ui';

import FieldCompatibility from './../../frontend/businesslayers/datasourcemodule/field-compatibility';
import FieldType from './../../frontend/components/datasourcemodule/field-type';

import Label from './../wrapper/label';
import SelectKeyNumber from './../../frontend/components/framework/selectkeynumber';
import Switch from './../wrapper/switch';

import './../../common.css';

function PathSynchroField(props) {

    var mappingLine = props.mapping.Mappings.find((x) => x.TargetFieldReferenceId === props.targetFieldReferenceId)

    var currentFieldSource = null;
    var isKey = false;
    var currentLinkedFieldSource = null;

    if (mappingLine != null)
    {
        currentFieldSource = props.fields.find((field) => field.Id === mappingLine.SourceFieldReferenceId);
        isKey = mappingLine.IsKey;
        currentLinkedFieldSource = mappingLine.LinkedFieldReferenceId;
    }

    const [currentField, setCurrentField] = useState(currentFieldSource);
    const [keyField, setKeyField] = useState(isKey);
    const [currentLinkedField, setCurrentLinkedField] = useState(currentLinkedFieldSource);

    const GetFieldList = () => {

        var fieldsUi = [];

        if (props.onlyCompatibleData && props.field.Type.Type !== 3000) {
            fieldsUi = props.fields
                .filter(field => (FieldCompatibility.Check(field, props.field)))
                .map(field => ({ value: field.Id, label: field.Label }));
        }
        else {
            fieldsUi = props.fields
                .map(field => ({ value: field.Id, label: field.Label }));
        }

        fieldsUi.unshift({ value: -1, label: "Choose a field" });

        return fieldsUi;
    }

    var fieldsUi = GetFieldList();

    useEffect(() => {

    }, [props.onlyCompatibleData]);

    const changeCurrentField = (id) => {

        var mappingLine = props.mapping.Mappings.find((x) => x.TargetFieldReferenceId === props.targetFieldReferenceId)

        if (mappingLine == null)
        {
            props.mapping.Mappings.push(
                {
                    IsKey:false,
                    TargetFieldReferenceId:props.targetFieldReferenceId,
                    SourceFieldReferenceId:id
                });
        }
        else
        {
            mappingLine.SourceFieldReferenceId = id;
        }

        props.setMapping(props.mapping);
        setCurrentField(props.fields.find((flow) => flow.Id === id));
        props.setCheckToDo(true);
    }

    const changeKeyField = (value) => {

        var mappingLine = props.mapping.Mappings.find((x) => x.TargetFieldReferenceId === props.targetFieldReferenceId)

        if (mappingLine == null)
        {
            props.mapping.Mappings.push(
                {
                    IsKey: value,
                    TargetFieldReferenceId: props.targetFieldReferenceId,
                    SourceFieldReferenceId: -1
                });
        }
        else
        {
            mappingLine.IsKey = value;
        }

        props.setMapping(props.mapping);
        setKeyField(value);
        props.setCheckToDo(true);
    }

    const changeLinked = (value) => {

        var mappingLine = props.mapping.Mappings.find((x) => x.TargetFieldReferenceId === props.targetFieldReferenceId)

        if (mappingLine == null) {
            props.mapping.Mappings.push(
                {
                    IsKey: value,
                    TargetFieldReferenceId: -1,
                    SourceFieldReferenceId: -1,
                    LinkedFieldReferenceId: value
                });
        }
        else {
            mappingLine.LinkedFieldReferenceId = value;
        }

        props.setMapping(props.mapping);
        setCurrentLinkedField(value);
        props.setCheckToDo(true);
    }

    return (
        <tr key={props.index} >
            <td className="align-middle"><Switch value={keyField} onChange={changeKeyField}></Switch></td>
            <td className="align-middle"><SelectKeyNumber
                options={fieldsUi}
                value={currentField != null ? currentField.Id : -1}
                onChange={newValue => changeCurrentField(newValue)} />
            </td>
            <td className="align-middle">
                <div className="start-line">
                    <Label width='100%'>{props.field.Name}</Label>
                    <FieldType field={props.field} dataSource={props.dataSource} currentLinkedField={currentLinkedField} setCurrentLinkedField={changeLinked} />
                </div>
            </td>
        </tr>);
}

export default PathSynchroField;