import React, {useState} from "react";

// import { TextButton } from "@airtable/blocks/ui";
import TextButton from './../../../components/wrapper/textbutton';
import Tooltip from "../framework/tooltip.js";

import ActionConst from "../action-const";

import DataSourceDialog from './datasource-dialog.js';

const DataSourceActions = (props) => {
   
    const [createDialogIsOpen, setCreateDialogIsOpen] = useState(false);
    const [updateDialogIsOpen, setUpdateDialogIsOpen] = useState(false);

    const openCreateDialog = () => setCreateDialogIsOpen(true);
    const openUpdateDialog = () => setUpdateDialogIsOpen(true);

    return (<React.Fragment>
                {
                    <Tooltip content="Create a datasource">
                        <TextButton margin={1} icon="plus" onClick={openCreateDialog}/>
                    </Tooltip>
                }
                {
                    <Tooltip content="Edit a datasource">
                        <TextButton margin={1} icon="edit" onClick={openUpdateDialog} disabled={props.currentDataSource===null} />
                    </Tooltip>
                }
                {
                    // TODO delete
                    <Tooltip content="Delete a datasource">
                        <TextButton margin={1} icon="trash" disabled={true} />
                    </Tooltip>
                }
                {createDialogIsOpen ? (<DataSourceDialog action={ActionConst.Create} setDialogIsOpen={setCreateDialogIsOpen} setDataSources={props.setDataSources} currentProject={props.currentProject} direction={props.direction} currentDataSource={null} />) : ("")}
                {updateDialogIsOpen ? (<DataSourceDialog action={ActionConst.Update} setDialogIsOpen={setUpdateDialogIsOpen} setDataSources={props.setDataSources} currentProject={props.currentProject} direction={props.direction} currentDataSource={props.currentDataSource} />) : ("")}
            </React.Fragment>
            );
}

export default DataSourceActions;