import React from 'react';

import PathSynchroFields from './PathSynchroFields.js';

import '../../common.css';
import './pathsynchro.css';

export function PathSynchroMapping(props) {

    return (
        <div className='nowcode-columns path-height'>
            <div className='path-synchro-70p path-synchro-mr nowcode-internal-block'>
                <PathSynchroFields
                    dataSourceSource={props.currentDataSource1}
                    dataSourceTarget={props.currentDataSource2}

                    databaseReferenceSource={props.currentDatabaseReference1}
                    databaseReferenceTarget={props.currentDatabaseReference2}

                    tableReferenceSource={props.currentTableReference1}
                    tableReferenceTarget={props.currentTableReference2}

                    fieldsSource={props.fields1}
                    fieldsTarget={props.fields2}

                    setCheckToDo={props.setCheckToDo}

                    mapping={props.mapping}
                    setMapping={props.setMapping}
                    fullLoadingInProgress={props.fullLoadingInProgress}
                />
            </div>
            <div className='path-synchro-30p nowcode-internal-block'>???</div>
        </div>
    );
}
