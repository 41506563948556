import React from 'react';

import { Input } from 'reactstrap';

function SelectKeyNumber(props) {

    const selectionChange = (key) => {
        if (props.onChange != null) {
            props.onChange(key);
        }
    };

    var style = {};

    if (props.width != null) {
        style.width = props.width;
    }

    if (props.marginLeft != null) {
        style.marginLeft = props.marginLeft;
    }

    if (props.marginRight != null) {
        style.marginRight = props.marginRight;
    }

    return (
        <Input type="select" value={props.value} onChange={e => selectionChange(!isNaN(e.target.value) ? parseInt(e.target.value) : null)} style={style} disabled={props.disabled} >
            {props.options != null ?
                props.options.map((e) => <option value={e.value} key={e.value}>{e.label}</option>) :
                ""}
        </Input>
    );
}

export default SelectKeyNumber