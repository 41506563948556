import TableReferenceIdAndKeyDto from "../../../dtos/TableReferenceIdAndKeyDto";

class GetTableReferencesResult {
    LastUpdateDate: Date;
    Tables: TableReferenceIdAndKeyDto[];

    constructor(lastUpdateDate: Date, tables: TableReferenceIdAndKeyDto[]) {
        this.LastUpdateDate = lastUpdateDate;
        this.Tables = tables;
    }
}

export default GetTableReferencesResult;
