import React, { useEffect, useState } from "react";

//import { FormField } from "@airtable/blocks/ui";
import FormField from '../../../components/wrapper/formfield';

import ActionConst from "../action-const";

import CustomerAgentApi from '../../apis/CustomerAgentApi';

import CustomerAgentSelect from "./customeragent-select.js";
function DataSourceWithCustomerAgent(props) {

    const [customerAgents, setCustomerAgents] = useState(null);

    useEffect(() => {
        if (!customerAgents) {
            CustomerAgentApi.GetCustomerAgentsAsync()
                .then(allCustomerAgentsResult => {
                    if (allCustomerAgentsResult.IsFailed()) {
                        // ERROR
                    }
                    setCustomerAgents(allCustomerAgentsResult.Result.CustomerAgents);

                    if (ActionConst.Update === props.action) {
                        var loaded = allCustomerAgentsResult.Result.CustomerAgents.find((customerAgent) => customerAgent.Id === props.current.CustomerAgentId);
                        if (loaded != null) {
                            props.setCustomerAgent(loaded);
                        }
                    }

                });
        }
    }, [customerAgents, props]);

    if (!customerAgents) return <div></div>;

    return (
        <FormField label="Agent">
            <CustomerAgentSelect
                customerAgents={customerAgents}
                customerAgent={props.customerAgent}
                setCustomerAgent={props.setCustomerAgent} />
        </FormField>);
}

export default DataSourceWithCustomerAgent;