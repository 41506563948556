import React, { Fragment, useState } from 'react';
import { Badge, Tooltip } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircleInfo } from '@fortawesome/free-solid-svg-icons'

import { FieldTypeHelper } from '../../translations/field-type-helper.js';

import TransformationDialog from './transformation-dialog.js';

import '../../styles/nowcode-toolboxline.css';
import '../../../common.css';

function FieldType(props) {

    const [tooltipOpen, setTooltipOpen] = useState(false);
    const toggle = () => setTooltipOpen(!tooltipOpen);

    const [updateDialogIsOpen, setUpdateDialogIsOpen] = useState(false);
    const openUpdateDialog = () => setUpdateDialogIsOpen(true);

    const clickOnBadge = () => {
        if (props.field.Type.Type === 3000) {
            openUpdateDialog();
        }
    }

    let className = "";
    if (props.field.Type.Type === 3000) {
        className = "nowcode-hover";
    }

    // TODO : Code de nuit
    var tableReference = { Id: props.field.Type.LinkTableId };

    return (
        <Fragment>
            <div className="nowcode-field-type">
                <Badge className={className} color={FieldTypeHelper.GetColor(props.field.Type)} pill onClick={clickOnBadge}>{props.field.Type.TypeLabel}</Badge>
                <FontAwesomeIcon id={'info_' + props.field.Id} icon={faCircleInfo} />
                <Tooltip
                    {...props}
                    isOpen={tooltipOpen}
                    target={'info_' + props.field.Id}
                    toggle={toggle}>
                    <div>
                        Original type : {props.field.Type.OriginTypeLabel}
                    </div>
                </Tooltip>
            </div>
            {updateDialogIsOpen ? (<TransformationDialog setDialogIsOpen={setUpdateDialogIsOpen} dataSource={props.dataSource} tableReference={tableReference} currentLinkedField={props.currentLinkedField} setCurrentLinkedField={props.setCurrentLinkedField} />) : ("")}
        </Fragment>
    );
}

export default FieldType;