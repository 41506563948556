import React from "react";

//import { Select } from "@airtable/blocks/ui";
import SelectKeyNumber from '../framework/selectkeynumber';

function DataSourceTypeSelect(props) {

    if (props.dataSourceTypes == null || props.dataSourceTypes.length === 0)
    {
        return (<div>NO DATA</div>);
    }

    var dataSourceTypesUi = props.dataSourceTypes.map(dataSourceType => (
        { value: dataSourceType.Id, label : dataSourceType.Name }
    ));

    dataSourceTypesUi.unshift({ value: -1, label : "Choose a data source type" });

    var currentDataSourceTypeId = props.dataSourceType != null ? props.dataSourceType.Id : -1;

    return (
        <SelectKeyNumber
            options={dataSourceTypesUi}
            value={currentDataSourceTypeId}
            onChange={Id => props.setDataSourceType(props.dataSourceTypes.find((dataSourceType) => dataSourceType.Id === Id) ?? null) }
        />
    );
}

export default DataSourceTypeSelect;
