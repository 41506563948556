import React from 'react';

function Box(props) {

    var style = {};

    if (props.width != null) {
        style.width = props.width;
    }

    return (
        <div style={style} className={props.className}>
            {props.children}
        </div>
    );
}

export default Box