import React from "react";

import SelectKeyNumber from '../framework/selectkeynumber';

function PathTypeSelect(props) {

    var pathTypesUi = props.pathTypes.map(pathType => (
        { value: pathType.Id, label : pathType.Name }
    ));

    pathTypesUi.unshift({ value: -1, label : "Choose a path type" });

    const changeCurrentPathType = (Id) => {
        props.setPathType(props.pathTypes.find((pathType) => pathType.Id === Id) ?? null);
    }

    if (props.pathTypes.length === 0)
    {
        return (<div>NO DATA</div>);
    }

    var currentPathTypeId = props.pathType != null ? props.pathType.Id : -1;

    return (
        <SelectKeyNumber
            options={pathTypesUi}
            value={currentPathTypeId}
            onChange={newValue => changeCurrentPathType(newValue) }
        />);
}

export default PathTypeSelect;
