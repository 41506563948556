import React, { useEffect, useState } from "react";

//import { Button, FormField, Input } from "@airtable/blocks/ui";

import { Input, Alert } from 'reactstrap';

import FormField from './../../../components/wrapper/formfield';
import Button from './../../../components/wrapper/button';

import Dialog from '../framework/dialog.js';
import DialogBody from "../framework/dialog-body.js";
import DialogFooter from "../framework/dialog-footer.js";

import ActionConst from "../action-const";

import SourceType from "./../../const/datasourcemodule/SourceType";

import DataSourceApi from '../../apis/DataSourceApi';

import DataSourceTypeSelect from "./datasourcetype-select.js";
import DataSourceConnectorTypeSelect from "./datasourceconnectortype-select";
import DataSourceDialogBody from "./datasource-dialog-body";

import DataSourceCrudLogic from "../../businesslayers/datasourcemodule/DataSourceCrudLogic";

import '../../styles/nowcode-toolboxline.css';

const DataSourceDialog = (props) => {

    const [dataSourceTypes, setDataSourceTypes] = useState(null);

    const [dataSourceType, setDataSourceType] = useState(null);
    const [dataSourceConnectorType, setDataSourceConnectorType] = useState(null);
    const [dataSourceName, setDataSourceName] = useState("");
    const [customerAgent, setCustomerAgent] = useState(null);
    const [connectionString, setConnectionString] = useState("");
    const [dataSourceCredential, setDataSourceCredential] = useState(null);

    const [invalid, setInvalid] = useState(false);

    const [current, setCurrent] = useState(null);

    const [crudLogic, setCrudLogic] = useState(null);

    if (crudLogic === null) {
        setCrudLogic(new DataSourceCrudLogic());
    }

    useEffect(() => {
        if (crudLogic === null) {
            return;
        }
        if (current === null) {
            crudLogic.CurrentDataSourceAccessId = null;
        } else {
            crudLogic.CurrentDataSourceAccessId = current.Id;
        }
    }, [current, crudLogic])

    const isValid = function() {

        if (dataSourceName == null || dataSourceName.length === 0) {
            return false;
        }

        if (dataSourceType == null || dataSourceConnectorType == null) {
            return false;
        }

        if (dataSourceConnectorType.Id === 3) {
            if (customerAgent == null || customerAgent.Id <= 0) {
                return false;
            }
        }

        if (dataSourceType.SourceType === SourceType.WithIncludedCredentials && dataSourceConnectorType.Id === 3) {
            return connectionString != null && connectionString.length > 0;
        }

        if (dataSourceType.SourceType === SourceType.WithSeparatedCredentials) {
            return dataSourceCredential != null && dataSourceCredential.Id > 0;
        }

        return false;
    }

    const createOrUpdateDataSource = function () {

        if (dataSourceType.SourceType === SourceType.WithIncludedCredentials && dataSourceConnectorType.Id === 3) {
            crudLogic.CreateOrUpdateDataSourceStandardDatabaseAsync(customerAgent.Id, dataSourceType.Id, dataSourceName, connectionString).then((processingResult) => {
                if (processingResult.Result.Ok) {
                    props.setDialogIsOpen(false);
                    props.setDataSources(null);
                } else {
                    setInvalid(true);
                }
            });
            return;
        }

        if (dataSourceType.SourceType === SourceType.WithSeparatedCredentials) {
            crudLogic.CreateOrUpdateDataSourceWithCredentialAsync(dataSourceType.Id, dataSourceConnectorType.Id, dataSourceName, customerAgent?.Id, dataSourceCredential.Id).then((processingResult) => {
                if (processingResult.Result.Ok) {
                    props.setDialogIsOpen(false);
                    props.setDataSources(null);
                }
                else {
                    setInvalid(true);
                }
            });
            return;
        }
    }

    const cancel = function() {
        props.setDialogIsOpen(false);
    }

    useEffect(() => {
        if (current == null) {
            if (props.currentDataSource != null) {
                DataSourceApi.GetDataSourceByIdAsync(props.currentDataSource.Id).then((e) => {
                    setDataSourceName(e.Result.Name);
                    setConnectionString(e.Result.ConnectionString ?? null);
                    setCurrent(e.Result);
                });
            }
        }
    }, [props.currentDataSource, current])

    useEffect(() => {
        if (props.currentDataSource === null || current != null) {
            if (!dataSourceTypes) {

                var readable = props.direction === "Source" ? true : null;
                var writable = props.direction === "Target" ? true : null;

                DataSourceApi.GetDataSourceTypesByCustomerAsync(readable, writable)
                    .then(getDataSourceTypesResult => {
                        if (getDataSourceTypesResult.IsFailed()) {
                            // ERROR
                        }
                        setDataSourceTypes(getDataSourceTypesResult.Result.DataSourceTypes);

                        if (ActionConst.Update === props.action) {
                            var loaded = getDataSourceTypesResult.Result.DataSourceTypes.find((dataSourceType) => dataSourceType.Id === current.Type) ?? null;
                            setDataSourceType(loaded);
                            if (loaded != null) {
                                var selected = loaded.ConnectorTypes.find((s) => s.Id === props.currentDataSource.ConnectorTypeId) ?? null;
                                setDataSourceConnectorType(selected);
                            }
                        }
                    });
            }
        }
    }, [current, dataSourceTypes, props.action, props.currentDataSource, props.direction]);

    useEffect(() => {
        if (dataSourceType == null) {
            setDataSourceConnectorType(null);
            return;
        }

        if (dataSourceType.ConnectorTypes.length === 0) {
            setDataSourceConnectorType(null);
        }
        else {
            var loaded = dataSourceType.ConnectorTypes.find((connectorType) => current != null && connectorType.Id === current.ConnectorType) ?? null;

            if (loaded != null) {
                setDataSourceConnectorType(loaded);
            } else {
                setDataSourceConnectorType(dataSourceType.ConnectorTypes[0]);
            }
        }
    }, [current, dataSourceType]);

    useEffect(() => {
    }, [dataSourceConnectorType]);


    if (!dataSourceTypes) return <div></div>;

    var connectorTypesCount = dataSourceType?.ConnectorTypes.length ?? 0;

    function getTitle() {
        switch (props.action) {
            case ActionConst.Create: return "Create a dataSource";
            case ActionConst.Update: return "Update a dataSource";
            case ActionConst.Delete: return "Delete a dataSource";
            default: return "???";
        }
    }

    return (
        <React.Fragment>
            {(
                <Dialog onClose={() => cancel()} title={getTitle()} width="320px">
                    <DialogBody>
                        <FormField label="Source type">
                            <DataSourceTypeSelect dataSourceTypes={dataSourceTypes} dataSourceType={dataSourceType} setDataSourceType={setDataSourceType} />
                        </FormField>
                        <FormField label="DataSource name">
                            <Input value={dataSourceName} onChange={e => setDataSourceName(e.target.value)} />
                        </FormField>
                        {
                            connectorTypesCount > 1 &&
                            (<FormField label="Type de connecteur">
                                <DataSourceConnectorTypeSelect
                                    dataSourceConnectorTypes={dataSourceType.ConnectorTypes}
                                    dataSourceConnectorType={dataSourceConnectorType}
                                    setDataSourceConnectorType={setDataSourceConnectorType}/>
                            </FormField>)
                        }
                        {
                            connectorTypesCount > 0 &&
                            (<DataSourceDialogBody
                                action={props.action}
                                current={current}
                                dataSourceType={dataSourceType}
                                dataSourceConnectorType={dataSourceConnectorType}
                                customerAgent={customerAgent}
                                setCustomerAgent={setCustomerAgent}
                                connectionString={connectionString}
                                setConnectionString={setConnectionString}
                                dataSourceCredential={dataSourceCredential}
                                setDataSourceCredential={setDataSourceCredential}
                            />)
                        }
                        <Alert color="danger" isOpen={invalid}>
                            Datasource is invalid !
                        </Alert>
                    </DialogBody>
                    <DialogFooter>
                        <div className="nowcode-dialog-footer">
                            {(props.action === ActionConst.Create) ? (<Button marginRight={1} onClick={createOrUpdateDataSource} disabled={!isValid()}>Create</Button>) : ("")}
                            {(props.action === ActionConst.Update) ? (<Button marginRight={1} onClick={createOrUpdateDataSource} disabled={!isValid()}>Update</Button>) : ("")}
                            <Button onClick={cancel}>Cancel</Button>
                        </div>
                    </DialogFooter>
                </Dialog>
            )}
        </React.Fragment>
    );
};

export default DataSourceDialog;