import React, { Component } from 'react';
import { AuthenticatedTemplate, UnauthenticatedTemplate } from '@azure/msal-react';
import './Home.css';
export class Runs extends Component {
  static displayName = Runs.name;

  render() {
    return (
      <div>
        <AuthenticatedTemplate>
        </AuthenticatedTemplate>
        <UnauthenticatedTemplate>
        </UnauthenticatedTemplate>
      </div>      
    );
  }
}
