class PathReportingMappingDto {
    FieldReferenceId: number;
    IsQueryable: boolean;
    IsRequired: boolean;
    DefaultValue: string | null;
    IsExposed: boolean;

    constructor(
        fieldReferenceId: number,
        isQueryable: boolean,
        isRequired: boolean,
        defaultValue: string | null,
        isExposed: boolean
    ) {
        this.FieldReferenceId = fieldReferenceId;
        this.IsQueryable = isQueryable;
        this.IsRequired = isRequired;
        this.DefaultValue = defaultValue;
        this.IsExposed = isExposed;
    }
}

export default PathReportingMappingDto;
