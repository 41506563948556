import React, { useState } from "react";

import { Modal, ModalHeader } from 'reactstrap';

function Dialog(props) {
    const [modal, setModal] = useState(true);

    const toggle = () => {
        if (modal) {
            props.onClose();
        }
        setModal(!modal);
    }

    return (
        <Modal isOpen={modal} toggle={toggle}>
            <ModalHeader toggle={toggle}>
                {props.title}
            </ModalHeader>
            {props.children}
        </Modal>
    );
}

export default Dialog;