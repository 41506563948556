class GetRequestPathSynchroResult {
    Status: number;
    Progress: number;
    ErrorCode: string | null;

    constructor(status: number, progress: number, errorCode: string | null) {
        this.Status = status;
        this.Progress = progress;
        this.ErrorCode = errorCode;
    }
}

export default GetRequestPathSynchroResult;
