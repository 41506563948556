class CreateRequestResult {
    Accepted: boolean;
    Token: string;

    constructor(accepted: boolean, token: string) {
        this.Accepted = accepted;
        this.Token = token;
    }
}

export default CreateRequestResult;
