const FieldCompatibility = {

    Check: function (source, target) {

        if (source.Type.Type === 0 || target.Type.Type === 0) {
            return false;
        }

        if (source.Type.Type === target.Type.Type) {
            return true;
        }

        return false;
    }
};

export default FieldCompatibility;