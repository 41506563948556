import React from "react";

import SelectKeyNumber from '../framework/selectkeynumber';

function DatabaseSelect(props) {

    var databasesUi = props.databases.map(database => (
        { value: database.Id, label : database.Name }
    ));

    databasesUi.unshift({ value: -1, label : "Choose a database" });

    const changeCurrentDatabase = (id) => {
        props.setCurrentDatabase(props.databases.find((database) => database.Id === id) ?? null);
    }

    if (props.databases.length === 0)
    {
        return (<div>NO DATA</div>);
    }

    var currentDatabaseId = props.currentDatabase != null ? props.currentDatabase.Id : -1;

    return (
        <SelectKeyNumber
            options={databasesUi}
            value={currentDatabaseId}
            onChange={newValue => changeCurrentDatabase(newValue)}
            disabled={props.databaseIsOpen}
        />);
}

export default DatabaseSelect;