const KeyLogic = {

    Check: function (mappings) {

        var nbKeysChecked = 0;
        var nbKeysOk = 0;

        for (var mapping of mappings) {
            if (mapping.IsKey) {
                nbKeysChecked++;
                if (mapping.SourceFieldReferenceId > 0) {
                    nbKeysOk++;
                }
            }
        }

        return nbKeysChecked > 0 && nbKeysChecked === nbKeysOk;
    }
};

export default KeyLogic;