import DataSourceTypeListDto from "../../../dtos/datasourcemodule/DataSourceTypeListDto";

class GetDataSourcesResult {
    DataSources: DataSourceTypeListDto[];

    constructor(dataSources: DataSourceTypeListDto[]) {
        this.DataSources = dataSources;
    }
}

export default GetDataSourcesResult;
