import DataSourceCommonDto from "./DataSourceCommonDto";

class DataSourceStandardDatabaseDto extends DataSourceCommonDto {
    ConnectionString: string;

    constructor(
        id: number,
        name: string,
        type: number,
        connectorType: number,
        customerAgentId: number,
        connectionString: string
    ) {
        super(id, name, type, connectorType, customerAgentId);
        this.ConnectionString = connectionString;
    }
}

export default DataSourceStandardDatabaseDto;
