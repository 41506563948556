﻿export default class ProcessingResult<T> {
    Result?: T;
    ErrorMessage?: string;

    constructor(result?: T, errorMessage?: string) {
        this.Result = result;
        this.ErrorMessage = errorMessage;
    }

    public IsSuccess(): boolean {
        return this.ErrorMessage === undefined;
    }

    public IsFailed(): boolean {
        return this.ErrorMessage !== undefined;
    }

    static Ok<U>(result?: U): ProcessingResult<U> {
        return new ProcessingResult<U>(result);
    }

    static Error<U>(errorMessage: string): ProcessingResult<U> {
        return new ProcessingResult<U>(undefined, errorMessage);
    }
}
