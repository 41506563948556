import React from "react";

//import { Select } from "@airtable/blocks/ui";
import SelectKeyNumber from '../framework/selectkeynumber';

function DataSourceConnectorTypeSelect(props) {

    if (props.dataSourceConnectorTypes == null) {
        return (<div>NO DATA</div>);
    }

    var dataSourceConnectorTypesUi = props.dataSourceConnectorTypes.map(dataSourceConnectorType => (
        { value: dataSourceConnectorType.Id, label: dataSourceConnectorType.Name }
    ));

    if (props.dataSourceConnectorTypes.length === 0) {
        return (<div>NO DATA</div>);
    }

    var currentDataSourceConnectorTypeId = props.dataSourceConnectorType != null ? props.dataSourceConnectorType.Id : -1;

    return (
        <SelectKeyNumber
            options={dataSourceConnectorTypesUi}
            value={currentDataSourceConnectorTypeId}
            onChange={Id => props.setDataSourceConnectorType(props.dataSourceConnectorTypes.find((dataSourceConnectorType) => dataSourceConnectorType.Id === Id) ?? null)}
        />
    );
}

export default DataSourceConnectorTypeSelect;
