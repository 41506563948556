class TableMappingReadDto {
    DataSourceId: number;
    DataSourceLabel: string;
    DatabaseReferenceId: number;
    DatabaseReferenceLabel: string;
    TableReferenceId: number;
    TableReferenceLabel: string;

    constructor(
        dataSourceId: number,
        dataSourceLabel: string,
        databaseReferenceId: number,
        databaseReferenceLabel: string,
        tableReferenceId: number,
        tableReferenceLabel: string
    ) {
        this.DataSourceId = dataSourceId;
        this.DataSourceLabel = dataSourceLabel;
        this.DatabaseReferenceId = databaseReferenceId;
        this.DatabaseReferenceLabel = databaseReferenceLabel;
        this.TableReferenceId = tableReferenceId;
        this.TableReferenceLabel = tableReferenceLabel;
    }
}

export default TableMappingReadDto;
