import React from 'react';

import { Input as ReactInput } from 'reactstrap';

function Input(props) {

    const selectionChange = (value) => {
        if (props.onChange != null) {
            props.onChange(value);
        }
    };

    return (
        <ReactInput className={props.className} value={props.value} onChange={selectionChange} />
    );
}

export default Input