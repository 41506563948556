class ScheduleDto {
    IsActive: boolean;
    Type: number;
    Interval: number;
    Time: string;

    constructor(isActive: boolean, type: number, interval: number, time: string) {
        this.IsActive = isActive;
        this.Type = type;
        this.Interval = interval;
        this.Time = time;
    }
}

export default ScheduleDto;
