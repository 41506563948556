import React, { useEffect, useState, useCallback } from 'react';

//import { Label, Box } from '@airtable/blocks/ui';
import Label from './../../../components/wrapper/label';
import Box from './../../../components/wrapper/box';

import DataSourceApi from '../../apis/DataSourceApi';

import DataSourceSelect from './datasource-select.js';
import DataSourceActions from './datasource-actions.js';

import Database from './database.js';

import '../../styles/nowcode-toolboxline.css';

function DataSource(props) {
    const [dataSources, setDataSources] = useState(null);

    function manageCurrent(allDataSources) {
        if (props.tableReferenceLoaded != null) {
            var currentDataSourceId = props.tableReferenceLoaded?.DataSourceId ?? -1;
            var dataSource = allDataSources.find((x) => x.Id === currentDataSourceId);
            if (dataSource != null) {
                props.setCurrentDataSource(dataSource);
            }
        } else {
            props.setCurrentDataSource(undefined);
        }
    }

    const dataSourcesLoad = useCallback(async()=>
    {
        if (!dataSources) {
            var readable = props.direction === "Source" ? true : null;
            var writable = props.direction === "Target" ? true : null;

            DataSourceApi.GetDataSourcesByCustomerAsync(readable, writable)
                .then(getDataSourcesResult => {
                    var allDataSources = getDataSourcesResult.Result.DataSources
                        .toSorted((a, b) => (a.Name.toLowerCase() > b.Name.toLowerCase()) ? 1 : (a.Name.toLowerCase() < b.Name.toLowerCase()) ? -1 : 0);

                    setDataSources(allDataSources);
                    manageCurrent(allDataSources);
                });
        } else {
            manageCurrent(dataSources);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dataSources, props.tableReferenceLoaded]);

    useEffect(() => {
        dataSourcesLoad()
    }, [dataSourcesLoad]);

    function changeCurrentDataSource(selectedDataSource) {
        props.setCurrentDatabaseReference(null);
        props.setCurrentTableReference(null);
        props.setFields(null);
        props.setCurrentDataSource(selectedDataSource);
    }

    if (!dataSources) return <div>Chargement en cours 🚀</div>;

    return (
        <Box width='100%'>
            <Box className='nowcode-toolboxline'>
                {dataSources.length === 0 ? 
                    <Box width='100%'><Label alignSelf='center' margin={0}>Continue by create your first data source</Label></Box> :
                    <DataSourceSelect
                        dataSources={dataSources}
                        currentDataSource={props.currentDataSource}
                        setCurrentDataSource={changeCurrentDataSource} />
                }
                <DataSourceActions
                    dataSources={dataSources}
                    setDataSources={setDataSources}
                    currentDataSource={props.currentDataSource}
                    direction={props.direction}/>
            </Box>
            {
                props.currentDataSource != null && 
                    <Database 
                        currentDataSource={props.currentDataSource} 

                        currentDatabaseReference={props.currentDatabaseReference} 
                        setCurrentDatabaseReference={props.setCurrentDatabaseReference}

                        currentTableReference={props.currentTableReference} 
                        setCurrentTableReference={props.setCurrentTableReference}
                        tableReferenceLoaded={props.tableReferenceLoaded}
                        mapping={props.mapping}
                        fullLoadingInProgress={props.fullLoadingInProgress}/>
            }
        </Box>
    );
}

export default DataSource;
