export default class PathListDto {

    Id: number;
    Name: string;
    TypeId: number;
    TypeLabel: string;
    CreatedBy: string;
    CreationDate: Date;
    UpdateDate: Date;
    CurrentPeriodRuns: number;
    AllPeriodsRuns: number;

    constructor(
        id: number,
        name: string,
        typeId: number,
        typeLabel: string,
        createdBy: string,
        creationDate: string | Date,
        updateDate: string | Date,
        currentPeriodRuns: number,
        allPeriodsRuns: number
    ) {
        this.Id = id;
        this.Name = name;
        this.TypeId = typeId;
        this.TypeLabel = typeLabel;
        this.CreatedBy = createdBy;
        this.CreationDate = new Date(creationDate);
        this.UpdateDate = new Date(updateDate);
        this.CurrentPeriodRuns = currentPeriodRuns;
        this.AllPeriodsRuns = allPeriodsRuns;
    }
}
