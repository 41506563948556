import Settings from "./Settings";
import { AuthToken } from "./AuthToken";

import ProcessingResult from "../framework/ProcessingResult";

import UserSession from "./UserSession";

import CreateOrUpdatePathResult from "../classes/api/result/pathmodule/CreateOrUpdatePathResult"

import PathListDto from "../classes/dtos/pathmodule/PathListDto";

const PathApi = {

    GetPathsAsync: async function (): Promise<ProcessingResult<PathListDto[]>> {
        let authSession = await AuthToken.get();

        var customerId = await UserSession.EnsureAsync(authSession.Token);

        if (customerId === -1) {
            return ProcessingResult.Error("Unhautorized");
        }

        const response = await fetch(Settings.api_url + "/Path/GetPaths?customerId=" + customerId.toString(), {
            method: "Get",
            mode: "cors",
            cache: "no-cache",
            credentials: 'include',
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + authSession.Token
            }
        });

        if (response.status === 200) {
            var data = await response.json();
            return ProcessingResult.Ok(data.paths.map((e: any) => new PathListDto(e.id, e.name, e.typeId, e.typeLabel, e.createdBy, e.creationDate, e.updateDate, e.currentPeriodRuns, e.allPeriodsRuns)));
        }

        return ProcessingResult.Error("Error = " + response.status.toString());
    },

    CreatePathAsync: async function (typeId: number, name: string): Promise<ProcessingResult<CreateOrUpdatePathResult>> {
        let authSession = await AuthToken.get();

        var customerId = await UserSession.EnsureAsync(authSession.Token);

        const response = await fetch(Settings.api_url + "/Path/CreatePath", {
            method: "Post",
            mode: "cors",
            cache: "no-cache",
            credentials: 'include',
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + authSession.Token
            },
            body: JSON.stringify({ 'customerId': customerId, 'path': { 'typeId': typeId, 'name': name } })
        });


        if (response.status === 200) {
            var data = await response.json();
            return ProcessingResult.Ok(new CreateOrUpdatePathResult(data.id));
        }

        return ProcessingResult.Error("Error = " + response.status.toString());
    }
};

export default PathApi;