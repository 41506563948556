import DataSourceTypeListDto from "../../../dtos/datasourcemodule/DataSourceTypeListDto";

class GetDataSourceTypesResult {
    DataSourceTypes: DataSourceTypeListDto[];

    constructor(dataSourceTypes: DataSourceTypeListDto[]) {
        this.DataSourceTypes = dataSourceTypes;
    }
}

export default GetDataSourceTypesResult;
